@charset "UTF-8";
/* Welcome to Compass.
 * In this file you should write your main styles. (or centralize your imports)
 * Import this file using the following HTML or equivalent:
 * <link href="/stylesheets/screen.css" media="screen, projection" rel="stylesheet" type="text/css" /> */
/* RESET */
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
  display: block; }

audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

audio:not([controls]) {
  display: none; }

[hidden] {
  display: none; }

html {
  margin: 0;
  padding: 0;
  font-size: 62.5%; }

body {
  margin: 0;
  padding: 0; }

body, button, input, select, textarea {
  font-family: sans-serif;
  color: #222; }

::-moz-selection {
  background: #ccc;
  color: #fff;
  text-shadow: none; }

::selection {
  background: #ccc;
  color: #fff;
  text-shadow: none; }

a {
  text-decoration: none;
  outline: none; }

a:focus {
  outline: none; }

a:hover, a:active {
  outline: none; }

p {
  margin: 0 0 1em 0; }

p:last-of-type {
  margin: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

blockquote {
  margin: 1em 40px; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

ins {
  background: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background: #ff0;
  color: #000;
  font-style: italic;
  font-weight: bold; }

pre, code, kbd, samp {
  font-family: monospace, monospace;
  _font-family: 'courier new', monospace; }

code {
  display: inline-block; }

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: none; }

q:before, q:after {
  content: "";
  content: none; }

small {
  font-size: 85%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

strong {
  font-weight: normal; }

ul, ol {
  margin: 0;
  padding: 0; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  margin: 0 0 0 0;
  font-size: 100%; }

ul, li {
  margin: 0;
  padding: 0;
  list-style-type: none; }

dd {
  margin: 0; }

nav ul, nav ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0; }

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle; }

figure {
  margin: 0; }

form {
  margin: 0; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

label {
  cursor: pointer; }

legend {
  border: 0;
  *margin-left: -7px;
  padding: 0; }

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle; }

button, input {
  line-height: normal;
  *overflow: visible; }

table button, table input {
  *overflow: auto; }

button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

input:invalid, textarea:invalid {
  background-color: #f0dddd; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  font-size: 62.5%; }

* {
  box-sizing: border-box; }

body {
  background: transparent no-repeat center top;
  font-family: verdana, sans-serif;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  color: #000; }

/* FCK (back-office) */
.fck h2 {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 500;
  color: #F1911C;
  margin: 1.4em 0 0.8em 0;
  text-transform: uppercase; }
  .fck h2:first-of-type {
    margin-top: 0.2em; }

.fck p {
  font-family: verdana, sans-serif;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  color: #000; }

.fck a.lien-catalogue {
  font-family: lato, sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  color: #F1911C;
  font-size: 16px;
  font-weight: 900; }
  .fck a.lien-catalogue:hover {
    color: #925A00;
    transition: all 0.2s ease-in-out; }

/* Layout */
.wrapper {
  width: 1000px;
  margin: 0 auto; }
  .wrapper::after {
    content: "";
    display: table;
    clear: both; }

.sidebar {
  width: 340px;
  float: left; }

.content-with-sidebar {
  width: 640px;
  margin-right: 20px;
  float: left; }

.padding {
  padding: 20px; }

.padding-horizontal {
  padding: 0 20px; }

.padding-vertical {
  padding: 20px 0; }

.no-padding {
  padding: 0; }

.g-row {
  margin-top: 20px;
  padding: 0 20px; }
  .g-row::after {
    content: "";
    display: table;
    clear: both; }
  .g-row.with-slider {
    width: 660px; }
    .g-row.with-slider .g-cell:last-of-type {
      margin-right: 20px; }
  .g-row.padding {
    padding: 10px 20px; }
  .g-row.no-padding {
    padding: 0; }
  .g-row.color2 {
    background: #F1911C;
    width: 1000px; }

.g-cell {
  margin-right: 20px;
  float: left;
  position: relative; }
  .g-cell::after {
    content: "";
    display: table;
    clear: both; }
  .g-cell:last-of-type {
    margin-right: 0; }

.g-200-1 {
  width: 200px; }

.g-200-2 {
  width: 420px; }

.g-200-3 {
  width: 640px; }

.g-225-1 {
  width: 225px; }

.g-225-2 {
  width: 470px; }

.g-225-3 {
  width: 715px; }

.g-225-4 {
  width: 960px; }

.g-mb {
  margin-bottom: 30px; }

.border-bottom {
  padding-bottom: 20px;
  border-bottom: 1px solid #F1911C; }
  .border-bottom::after {
    content: "";
    display: table;
    clear: both; }
  .g-row:last-of-type .border-bottom {
    padding-bottom: 0;
    border: none; }

a.g-row {
  display: block;
  color: #000;
  transition: all 0.2s ease-in-out; }
  a.g-row:hover, a.g-row:focus, a.g-row:active {
    background: #e3e3e3; }

.asterix {
  position: absolute;
  right: 30px;
  bottom: -15px;
  color: #925A00;
  font-size: 10px;
  font-size: 1rem; }

.asterixdetail {
  color: #925A00;
  font-size: 10px;
  font-size: 1rem;
  margin-top: 5px; }

.mt-100 {
  margin: 80px 0 -15px 0; }

/* Titles */
.title-lev1,
.title-lev2,
.title-lev3 {
  font-family: lato, sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  color: #925A00; }
  .title-lev1 strong,
  .title-lev2 strong,
  .title-lev3 strong {
    font-weight: 900; }
  .title-lev1 a,
  .title-lev2 a,
  .title-lev3 a {
    color: #925A00;
    text-decoration: none;
    transition: all 0.2s ease-in-out; }
    .title-lev1 a:hover, .title-lev1 a:focus, .title-lev1 a:active,
    .title-lev2 a:hover,
    .title-lev2 a:focus,
    .title-lev2 a:active,
    .title-lev3 a:hover,
    .title-lev3 a:focus,
    .title-lev3 a:active {
      color: #F1911C; }

.title-lev1 {
  margin: 0 0 20px 0;
  font-size: 30px;
  font-size: 3rem; }
  .title-lev1.alt {
    background-color: #925A00;
    color: #fff;
    padding-top: 10px;
    padding-bottom: 10px; }

.title-lev2 {
  margin: 0 0 10px 0;
  font-size: 20px;
  font-size: 2rem; }

.title-lev3 {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 900;
  color: #F1911C; }

.title-lev6 {
  margin-bottom: 2px;
  text-transform: uppercase;
  font-weight: bold; }

.title-brown {
  display: inline-block;
  margin-right: 40px;
  padding: 0 20px;
  line-height: 32px;
  float: left;
  position: relative;
  background: #925A00;
  font-family: lato, sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase; }
  .title-brown:after {
    display: block;
    content: "";
    width: 40px;
    height: 32px;
    position: absolute;
    top: 0;
    right: -40px;
    background: transparent url("/img/borders-32.png") no-repeat 0 -32px; }
  .title-brown.alt:after {
    background-position: -80px -32px; }

/* Typography */
p {
  margin-bottom: 1em; }
  p:last-of-type {
    margin-bottom: 0; }

strong {
  font-weight: bold; }

em {
  font-style: italic; }

ul {
  margin-bottom: 1em; }

.btn {
  display: inline-block;
  height: 26px;
  margin-top: 3px;
  background: #fff;
  border: none;
  font-family: lato, sans-serif;
  font-weight: 700;
  font-size: 16px;
  font-size: 1.6rem;
  text-transform: uppercase;
  color: #925A00;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.2s ease-in-out; }
  .btn:hover, .btn:focus, .btn:active {
    background: #e3e3e3;
    color: #000; }

/* Navigation */
.nav-main {
  display: block;
  width: 100%;
  height: 40px;
  z-index: 10;
  position: absolute;
  right: 0;
  bottom: 0; }
  .nav-main > ul {
    text-align: right;
    padding: 0;
    position: relative;
    display: flex;
    justify-content: flex-end; }
    .nav-main > ul > li {
      display: inline-block;
      line-height: 40px; }
      .nav-main > ul > li .first-level {
        display: block;
        padding: 0 9px;
        font-family: lato, sans-serif;
        font-weight: 700;
        color: #925A00;
        color: #794a00;
        text-transform: uppercase;
        text-decoration: none;
        text-align: right !important;
        letter-spacing: 0.05em;
        transition: all 0.2s ease-in-out; }
        .nav-main > ul > li .first-level:hover, .nav-main > ul > li .first-level:focus, .nav-main > ul > li .first-level:active, .nav-main > ul > li .first-level.current, .nav-main > ul > li .first-level.selected {
          color: #925A00;
          cursor: pointer; }
      .nav-main > ul > li .subnav-container {
        width: 1000px;
        min-height: 420px;
        position: absolute;
        top: 100%;
        left: 0;
        margin: 0;
        padding: 10px 15px;
        text-align: left;
        background-color: #a4be34;
        visibility: hidden;
        opacity: 0; }
        .nav-main > ul > li .subnav-container::after {
          content: "";
          display: table;
          clear: both; }
        .no-touch .nav-main > ul > li .subnav-container {
          transition: top 0.1s ease-in-out, opacity 0.2s ease-in-out, visibility 0.1s ease-in-out; }
        .nav-main > ul > li .subnav-container:before, .nav-main > ul > li .subnav-container:after {
          display: block;
          content: '';
          position: absolute; }
        .nav-main > ul > li .subnav-container:before {
          width: 209px;
          height: 224px;
          background: transparent url("../../img/alt-quicksearch-dropdown.png") no-repeat 0 0;
          background-size: 209px 224px;
          bottom: 0;
          right: 0;
          opacity: 0.8; }
        .nav-main > ul > li .subnav-container:after {
          width: 1000px;
          height: 18px;
          background: transparent url("../../img/bottom-subnav-dropdown.png") no-repeat 0 0;
          background-size: 1000px 18px;
          top: 100%;
          left: 0; }
        .nav-main > ul > li .subnav-container.subnav-container--destination {
          padding-left: 414px; }
        .nav-main > ul > li .subnav-container ul.subnav {
          width: 100%;
          padding: 10px; }
          .nav-main > ul > li .subnav-container ul.subnav > li {
            line-height: 2em; }
            .nav-main > ul > li .subnav-container ul.subnav > li a {
              display: block;
              color: #fff;
              text-decoration: none;
              transition: color 0.3s ease-in-out; }
              .nav-main > ul > li .subnav-container ul.subnav > li a:hover {
                color: #925A00; }
          .nav-main > ul > li .subnav-container ul.subnav.subnav--picto {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center; }
            .nav-main > ul > li .subnav-container ul.subnav.subnav--picto > li {
              display: inline-grid;
              width: 220px;
              text-align: center; }
              .nav-main > ul > li .subnav-container ul.subnav.subnav--picto > li img.item__picto {
                width: 100px;
                height: auto; }
              .nav-main > ul > li .subnav-container ul.subnav.subnav--picto > li span.item__title {
                display: block;
                max-width: 80%;
                margin: 10px auto;
                font-size: 18px;
                font-size: 1.8rem;
                line-height: 1.3em; }
              .nav-main > ul > li .subnav-container ul.subnav.subnav--picto > li p.item__desc {
                max-width: 85%;
                margin: 0 auto;
                font-family: lato, sans-serif;
                font-size: 13px;
                font-size: 1.3rem;
                line-height: 1.4em;
                font-weight: 300;
                color: rgba(255, 255, 255, 0.9); }
          .nav-main > ul > li .subnav-container ul.subnav.subnav--text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center; }
            .nav-main > ul > li .subnav-container ul.subnav.subnav--text li:after {
              display: block;
              content: "";
              margin: 0 auto;
              height: 1px;
              width: 120px;
              background-color: rgba(255, 255, 255, 0.2); }
            .nav-main > ul > li .subnav-container ul.subnav.subnav--text li:last-of-type:after {
              opacity: 0; }
            .nav-main > ul > li .subnav-container ul.subnav.subnav--text li a {
              font-size: 18px;
              font-size: 1.8rem;
              line-height: 2.2em; }
          .nav-main > ul > li .subnav-container ul.subnav.subnav--destination {
            padding-top: 40px; }
            .nav-main > ul > li .subnav-container ul.subnav.subnav--destination::after {
              content: "";
              display: table;
              clear: both; }
            .nav-main > ul > li .subnav-container ul.subnav.subnav--destination li {
              text-align: left; }
              .nav-main > ul > li .subnav-container ul.subnav.subnav--destination li:after {
                margin: 0; }
              .nav-main > ul > li .subnav-container ul.subnav.subnav--destination li.subnav--destination--col {
                float: left;
                margin-left: 60px; }
                .nav-main > ul > li .subnav-container ul.subnav.subnav--destination li.subnav--destination--col:after {
                  display: none; }
              .nav-main > ul > li .subnav-container ul.subnav.subnav--destination li.destination:after {
                display: block;
                content: "";
                height: 1px;
                background-color: rgba(255, 255, 255, 0.2); }
              .nav-main > ul > li .subnav-container ul.subnav.subnav--destination li.destination:last-of-type:after {
                opacity: 0; }
              .nav-main > ul > li .subnav-container ul.subnav.subnav--destination li.destination a {
                font-size: 14px;
                font-size: 1.4rem;
                line-height: 2em; }
              .nav-main > ul > li .subnav-container ul.subnav.subnav--destination li span.region {
                color: #fff;
                text-transform: uppercase;
                font-size: 16px;
                font-size: 1.6rem;
                font-weight: 900;
                display: inline-block;
                margin-bottom: 10px; }

.nav-main > ul > li.is-open {
  background-color: #a4be34; }
  .nav-main > ul > li.is-open > .subnav-container {
    visibility: visible;
    opacity: 1; }

.nav-social {
  position: absolute;
  top: 20px;
  right: 14px; }
  .nav-social li {
    display: inline-block;
    position: relative; }
    .tiret .nav-social li::after {
      content: " - "; }
  .nav-social a {
    display: block;
    color: #000;
    text-decoration: none;
    line-height: 20px;
    transition: all 0.2s ease-in-out; }
    .nav-social a.linktext {
      line-height: 20px; }
      .nav-social a.linktext:hover {
        color: #F1911C; }
    .nav-social a.tiret::after {
      content: " -"; }
    .nav-social a.icon {
      padding-left: 20px;
      width: 15px;
      height: 15px; }
    .nav-social a.facebook {
      background: transparent url(../../img/icon-facebook.png) 0 0 no-repeat; }
      .nav-social a.facebook:hover, .nav-social a.facebook:focus, .nav-social a.facebook:active {
        background-position: 0 -15px;
        color: #364E8C; }
    .nav-social a.googleplus {
      background: transparent url(../../img/icon-googleplus.png) 0 0 no-repeat; }
      .nav-social a.googleplus:hover, .nav-social a.googleplus:focus, .nav-social a.googleplus:active {
        background-position: 0 -15px;
        color: #d6492f; }
    .nav-social a.instagram {
      background: transparent url(../../img/icon-instagram.png) 0 0 no-repeat; }
      .nav-social a.instagram:hover, .nav-social a.instagram:focus, .nav-social a.instagram:active {
        background-position: 0 -15px;
        color: #ec308d; }
    .nav-social a.vimeo {
      background: transparent url(../../img/icon-vimeo.png) 0 0 no-repeat; }
      .nav-social a.vimeo:hover, .nav-social a.vimeo:focus, .nav-social a.vimeo:active {
        background-position: 0 -15px;
        color: #00ADEF; }
    .nav-social a.youtube {
      width: 40px;
      padding-left: 44px;
      background: transparent url(../../img/icon-youtube.png) 0 0 no-repeat; }
      .nav-social a.youtube:hover, .nav-social a.youtube:focus, .nav-social a.youtube:active {
        background-position: 0 -15px;
        color: #ec2027; }
  .nav-social span {
    margin: 0 5px 0 0;
    top: 0px;
    left: 0;
    font-size: 16px;
    font-size: 1.6rem;
    position: absolute; }

.nav-items {
  display: inline-block; }
  .nav-items li {
    float: left;
    margin-right: 20px; }
    .nav-items li:last-of-type {
      margin-right: 0; }
    .nav-items li a {
      display: inline-block;
      line-height: 32px;
      font-family: lato, sans-serif;
      font-weight: 900;
      text-transform: uppercase;
      text-decoration: none;
      color: #925A00;
      transition: all 0.2s ease-in-out; }
      .nav-items li a:hover, .nav-items li a:focus, .nav-items li a:active, .nav-items li a.current {
        color: #F1911C; }

/* Formulaire expérience */
.ajout-exp {
  margin: 40px 0 0 20px;
  border-top: 1px solid #F1911C;
  padding-top: 15px; }
  .ajout-exp form {
    width: 960px;
    background: #eee;
    padding: 20px; }
    .ajout-exp form::after {
      content: "";
      display: table;
      clear: both; }
    .ajout-exp form h2 {
      padding-bottom: 10px; }
    .ajout-exp form ul {
      width: 960px;
      float: left; }
      .ajout-exp form ul li {
        margin: 5px 0px;
        float: left;
        border: 1px solid transparent; }
        .ajout-exp form ul li label {
          width: 250px;
          height: 35px;
          display: block;
          color: #fff;
          float: left;
          line-height: 35px;
          background: #a4be34;
          padding: 0 15px; }
        .ajout-exp form ul li input.champ {
          width: 670px;
          height: 35px;
          float: left;
          border: none;
          outline: none;
          padding: 10px; }
          .ajout-exp form ul li input.champ:hover, .ajout-exp form ul li input.champ:focus {
            border: 1px solid #a4be34;
            transition: all 0.2s ease-in-out; }
    .ajout-exp form ul.detail {
      margin-top: -10px; }
      .ajout-exp form ul.detail li.message {
        width: 920px;
        height: 35px;
        display: block;
        color: #fff;
        float: left;
        line-height: 33px;
        background: #a4be34;
        padding-left: 15px;
        margin-left: 1px; }
      .ajout-exp form ul.detail textarea {
        width: 920px;
        height: 150px;
        border: none;
        outline: none;
        padding: 10px 15px;
        float: left;
        border: 1px solid transparent; }
        .ajout-exp form ul.detail textarea:hover, .ajout-exp form ul.detail textarea:focus {
          border: 1px solid #a4be34;
          transition: all 0.2s ease-in-out; }
    .ajout-exp form button {
      width: 920px;
      height: 35px;
      font-size: 12px;
      font-size: 1.2rem;
      background: #efefef;
      color: #F1911C;
      border: 1px solid #F1911C;
      margin: 0; }
      .ajout-exp form button:hover {
        color: #fff;
        background: #F1911C; }

/* Modules */
.header-main {
  height: 95px;
  position: relative; }
  .header-main:after {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 50%, white 100%);
    opacity: 0.7; }
  .header-main:before {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background-color: rgba(120, 154, 0, 0.85);
    opacity: 0; }
  .header-main.dropdown:before {
    opacity: 1; }
  .header-main.dropdown .first-level {
    transition: none;
    color: #fff; }
    .header-main.dropdown .first-level:hover, .header-main.dropdown .first-level:focus, .header-main.dropdown .first-level:active, .header-main.dropdown .first-level.current, .header-main.dropdown .first-level.selected {
      color: #fff; }
  .header-main.dropdown .logo-amarok {
    background: transparent url("/img/logo-amarok-white.png") no-repeat center center; }

.logo-amarok {
  display: block;
  width: 200px;
  height: 95px;
  float: left;
  position: relative;
  z-index: 20;
  background: transparent url("/img/logo-amarok.png") no-repeat center center;
  text-indent: -9999px; }

.intro {
  margin-bottom: 40px;
  font-family: "Lobster Two", serif;
  font-size: 22px;
  font-size: 2.2rem;
  font-weight: 400;
  font-style: italic;
  color: #979797; }

/* Player home */
.home-banner {
  position: relative;
  width: 1000px;
  height: 420px; }

.nav-quicksearch {
  display: block;
  width: 209px;
  height: 224px;
  padding: 65px 10px 10px 55px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 3;
  background: transparent url("/img/bg-quicksearch.png") no-repeat 0 0; }
  .nav-quicksearch h3 {
    text-transform: uppercase;
    color: #F1911C;
    font-family: lato, sans-serif;
    font-weight: 900;
    font-size: 14px;
    font-size: 1.4rem;
    margin-bottom: 10px; }
  .nav-quicksearch ul li select {
    width: 135px;
    margin-bottom: 5px; }
  .nav-quicksearch ul li button {
    width: 135px;
    font-size: 14px;
    font-size: 1.4rem;
    background: #F1911C;
    color: #fff;
    margin-top: 7px; }
    .nav-quicksearch ul li button:hover {
      color: #F1911C; }

/***** POPUP vidéo *****/
#popup {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1002; }
  #popup .video {
    width: 720px;
    height: 420px;
    position: absolute;
    top: 50%;
    margin-top: -210px;
    left: 50%;
    margin-left: -360px;
    background: #000;
    background: rgba(255, 255, 255, 0.3);
    padding: 20px; }
    #popup .video iframe {
      width: 100%;
      height: 100%; }
    #popup .video p {
      display: none; }
    #popup .video a.close {
      color: #aaa;
      text-decoration: none;
      position: absolute;
      top: 5px;
      right: 8px; }
      #popup .video a.close:hover {
        color: #ddd; }

/***** Slideshow (home) *****/
#container {
  width: 1000px;
  height: 420px;
  position: relative; }

#slides {
  position: absolute;
  z-index: 1; }

.slides_container {
  width: 1000px;
  overflow: hidden;
  position: relative;
  display: none; }
  .slides_container a.slideshow {
    width: 1000px;
    height: 420px;
    display: block; }
    .slides_container a.slideshow .slideshow-legend {
      display: block;
      width: 1000px;
      padding: 15px 20px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: #000;
      background: rgba(0, 0, 0, 0.2);
      color: #fff; }
      .slides_container a.slideshow .slideshow-legend h2 {
        margin-bottom: 5px;
        font-family: lato, sans-serif;
        font-size: 30px;
        font-size: 3rem;
        font-weight: 300;
        text-transform: uppercase; }
        .slides_container a.slideshow .slideshow-legend h2 strong {
          font-weight: 900; }
      .slides_container a.slideshow .slideshow-legend p {
        width: 640px; }
      .slides_container a.slideshow .slideshow-legend img {
        display: block;
        width: 1000px; }

/***** Slideshow (home) *****/
#container {
  width: 1000px;
  height: 420px;
  position: relative; }

#slides {
  position: absolute;
  z-index: 1; }

.slides_container {
  width: 1000px;
  overflow: hidden;
  position: relative;
  display: none; }
  .slides_container a.slideshow {
    width: 1000px;
    height: 420px;
    display: block; }
    .slides_container a.slideshow .slideshow-legend {
      display: block;
      width: 1000px;
      padding: 15px 20px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: #000;
      background: rgba(0, 0, 0, 0.2);
      color: #fff; }
      .slides_container a.slideshow .slideshow-legend h2 {
        margin-bottom: 5px;
        font-family: lato, sans-serif;
        font-size: 30px;
        font-size: 3rem;
        font-weight: 300;
        text-transform: uppercase; }
        .slides_container a.slideshow .slideshow-legend h2 strong {
          font-weight: 900; }
      .slides_container a.slideshow .slideshow-legend p {
        width: 640px; }
      .slides_container a.slideshow .slideshow-legend img {
        display: block;
        width: 1000px; }

/* Next/prev button */
#slides .next, #slides .prev {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 21px;
  height: 21px;
  display: block;
  z-index: 101; }
  #slides .next:hover, #slides .prev:hover {
    background: #000;
    transition: all 0.2s ease-in-out; }

#slides .prev {
  right: 42px; }

/* Pagination */
.pagination {
  position: absolute;
  bottom: 20px;
  right: 20px;
  margin: 0;
  display: block;
  z-index: 20;
  background: #fff;
  background: rgba(255, 255, 255, 0.6);
  padding: 10px; }
  .pagination li {
    float: left;
    margin: 0 2px;
    list-style: none; }
    .pagination li a {
      display: block;
      width: 12px;
      height: 0;
      padding-top: 12px;
      background-image: url(../../img/slider/pagination.png);
      background-position: 0 0;
      float: left;
      overflow: hidden; }
    .pagination li.current a {
      background-position: 0 -12px; }

.arrows {
  float: left;
  margin-right: 1px;
  height: 32px;
  z-index: 201; }
  .arrows a {
    display: block;
    float: left;
    width: 32px;
    height: 32px; }
    .arrows a.prev-minislide {
      background: transparent url(../../img/arrow-prev2.png) 0 0 no-repeat;
      margin-right: 1px; }
      .arrows a.prev-minislide:hover {
        opacity: 0.8;
        transition: all 0.3s ease-in-out; }
    .arrows a.next-minislide {
      background: transparent url(../../img/arrow-next2.png) 0 0 no-repeat; }
      .arrows a.next-minislide:hover {
        opacity: 0.8;
        transition: all 0.3s ease-in-out; }

.travel-item {
  display: block;
  text-decoration: none; }
  .travel-item::after {
    content: "";
    display: table;
    clear: both; }
  .travel-item .travel-destination,
  .travel-item .travel-title {
    margin: 0;
    font-family: lato, sans-serif;
    font-size: 13px;
    font-size: 1.3rem;
    font-weight: 800;
    text-transform: uppercase;
    color: #000;
    transition: all 0.2s ease-in-out; }
    .color2 .travel-item .travel-destination, .color2
    .travel-item .travel-title {
      color: #fff; }
  .travel-item .travel-title {
    display: block;
    margin-bottom: 4px;
    min-height: 25px;
    font-size: 10px;
    font-size: 1rem;
    font-weight: 300; }
  .travel-item img {
    height: 150px;
    width: 100%;
    transition: all 0.2s ease-in-out; }
  .travel-item:hover .travel-destination, .travel-item:focus .travel-destination, .travel-item:active .travel-destination {
    color: #925A00; }
  .travel-item:hover img, .travel-item:focus img, .travel-item:active img {
    opacity: 0.8; }

.splash-item {
  display: block;
  width: 365px;
  height: 225px;
  margin-top: 20px;
  padding: 20px;
  position: relative;
  background: transparent url("/img/bg-splash.png") no-repeat 0 0; }
  .splash-item.alt {
    background-position: 0 -236px; }
  .splash-item a {
    display: block;
    color: #000;
    text-decoration: none;
    height: 130px;
    transition: all 0.2s ease-in-out; }
    .splash-item a::after {
      content: "";
      display: table;
      clear: both; }
    .splash-item a:hover, .splash-item a:focus, .splash-item a:active {
      color: #4d4d4d; }
      .splash-item a:hover img, .splash-item a:focus img, .splash-item a:active img {
        opacity: 0.8; }
  .splash-item .splash-title {
    margin-bottom: 10px;
    font-family: lato, sans-serif;
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: 900;
    text-transform: uppercase;
    color: #fff;
    transition: all 0.2s ease-in-out; }
  .splash-item .small-title {
    margin-bottom: 10px;
    margin-right: 20px;
    font-weight: bold; }
  .splash-item img {
    max-width: 130px;
    max-height: 130px;
    margin: 0 20px 20px 0;
    float: left;
    transition: all 0.2s ease-in-out; }
  .splash-item .meta-light {
    font-size: 10px;
    font-size: 1rem;
    color: #fff; }
  .splash-item p {
    margin-right: 20px; }

/* Filter Nav */
.header-filter {
  padding: 20px; }
  .header-filter .title-lev1 {
    margin-bottom: 0; }

.nav-filter {
  height: 32px;
  line-height: 32px;
  margin: 0 20px;
  position: relative;
  background: #925A00; }
  .nav-filter::after {
    content: "";
    display: table;
    clear: both; }
  .nav-filter::before, .nav-filter::after {
    display: block;
    content: "";
    width: 40px;
    height: 32px;
    position: absolute;
    top: 0;
    left: -18px;
    background: transparent url("/img/borders-32.png") no-repeat -40px -32px; }
  .nav-filter::after {
    left: auto;
    right: -40px;
    background-position: 0 -32px; }
  .nav-filter li {
    width: 184px;
    float: left; }
  .nav-filter select {
    width: 90%; }
  .nav-filter .btn {
    width: 90%; }
  .title-lev1 + .nav-filter {
    margin-top: 1em; }

.voyage-liste .g-225-3 {
  height: 170px; }

.voyage-liste .travel-list-meta {
  margin-bottom: 0;
  position: absolute;
  left: 0;
  bottom: 0; }

.voyage-liste .travel-price {
  position: absolute;
  right: 0;
  bottom: 0; }

.travel-label {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: block;
  line-height: 22px;
  background-color: #a4be34;
  font-size: 10px;
  color: #fff;
  text-transform: uppercase; }
  .travel-label img {
    float: left;
    margin: 0 5px 0 2px; }

.travel-info {
  padding: 20px 20px 25px 20px;
  background-color: #f5f5f5; }
  .travel-info.travel-info--intro {
    margin: 20px 0; }
  .travel-info.travel-info--outro {
    margin: 20px; }

.travel-price {
  display: inline-block;
  height: 32px;
  line-height: 32px;
  margin-right: 40px;
  padding: 0 10px;
  position: relative;
  background: #925A00;
  font-family: lato, sans-serif;
  font-weight: 900;
  font-size: 20px;
  font-size: 2rem;
  color: #fff; }
  .travel-price::after {
    content: "";
    display: block;
    height: 32px;
    width: 40px;
    position: absolute;
    top: 0;
    right: -40px;
    background: transparent url("/img/borders-32.png") no-repeat -120px -32px; }

.travel-details::after {
  content: "";
  display: table;
  clear: both; }

.travel-details .travel-details-meta {
  width: 270px;
  margin-right: 20px;
  float: left;
  position: relative; }
  .travel-details .travel-details-meta .travel-label {
    position: relative; }
  .travel-details .travel-details-meta ul.travel-list-meta li {
    line-height: 20px; }
  .travel-details .travel-details-meta a.reserve {
    display: block;
    width: 171px;
    height: 46px;
    background: transparent url(../../img/bouton-reserve.png) 0 0 no-repeat;
    line-height: 38px;
    padding: 0 10px;
    text-align: center;
    text-decoration: none;
    font-family: lato, sans-serif;
    font-weight: 700;
    font-size: 18px;
    font-size: 1.8rem;
    color: #fff;
    margin: 0 0 12px 40px; }
    .travel-details .travel-details-meta a.reserve:hover {
      color: #a4be34; }
  .travel-details .travel-details-meta .guide-list::after {
    content: "";
    display: table;
    clear: both; }
  .travel-details .travel-details-meta .guide-item {
    float: left;
    width: 130px;
    height: 130px;
    position: relative;
    color: white; }
    .travel-details .travel-details-meta .guide-item:nth-of-type(odd) {
      margin-right: 10px; }
    .travel-details .travel-details-meta .guide-item span {
      display: block;
      width: 130px;
      padding: 2px;
      position: absolute;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.2);
      text-align: center; }
  .travel-details .travel-details-meta .img-info {
    width: 140px;
    height: 60px;
    position: absolute;
    right: 0;
    top: 105px; }
  .travel-details .travel-details-meta ul.travel-link li a {
    display: block;
    width: 240px;
    height: 26px;
    position: relative;
    margin-bottom: 4px;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    line-height: 26px;
    font-size: 0.9em;
    padding-left: 6px; }
    .travel-details .travel-details-meta ul.travel-link li a:hover {
      color: #925A00; }
    .travel-details .travel-details-meta ul.travel-link li a.question {
      background: #F1911C; }
      .travel-details .travel-details-meta ul.travel-link li a.question:after {
        content: "";
        display: block;
        width: 19px;
        height: 26px;
        position: absolute;
        top: 0;
        right: -19px;
        background: transparent url("/img/borders-26.png") 0 0 no-repeat; }
    .travel-details .travel-details-meta ul.travel-link li a.telecharge {
      background: #a4be34; }
      .travel-details .travel-details-meta ul.travel-link li a.telecharge::after {
        content: "";
        display: block;
        width: 19px;
        height: 26px;
        position: absolute;
        top: 0;
        right: -19px;
        background: transparent url("/img/borders-26.png") 0 -26px no-repeat; }

.travel-details-precision {
  margin-top: 34px; }
  .travel-details-precision ul.details-menu {
    display: block;
    height: 34px;
    width: 100%; }
    .travel-details-precision ul.details-menu li {
      float: left; }
      .travel-details-precision ul.details-menu li a {
        display: block;
        height: 34px;
        background: #babbbb;
        color: #fff;
        text-transform: uppercase;
        text-decoration: none;
        line-height: 34px;
        text-align: center; }
        .travel-details-precision ul.details-menu li a:hover, .travel-details-precision ul.details-menu li a.selected {
          background: #925A00; }
  .travel-details-precision .detail::after {
    content: "";
    display: table;
    clear: both; }
  .travel-details-precision .detail .colonne {
    width: 310px;
    float: left;
    margin-right: 35px; }
    .travel-details-precision .detail .colonne:last-of-type {
      margin: 0; }
  .travel-details-precision .detail.hide {
    display: none; }
  .travel-details-precision .detail ul li {
    list-style-type: none;
    margin-top: 8px;
    line-height: 17px;
    padding-left: 22px;
    background: transparent url(../../img/puce.png) 0 1px no-repeat; }
  .travel-details-precision .detail a.galerie {
    display: block;
    float: left;
    width: 247px;
    height: 150px;
    margin: 0 3px 3px 0; }

.programme h2 {
  list-style-type: none;
  margin: 8px 0;
  line-height: 17px;
  padding-left: 22px;
  font-weight: bold;
  background: transparent url(../../img/puce.png) 0 1px no-repeat; }

.programme #map {
  float: right;
  margin: 0 0 10px 10px; }

.voyage ul, .voyage .text, .programme ul, .programme .text, .avis ul, .avis .text, .photo ul, .photo .text {
  width: 460px;
  text-align: justify;
  margin-right: 40px;
  float: left; }

.voyage .ptsforts, .programme .ptsforts, .avis .ptsforts, .photo .ptsforts {
  display: block;
  float: left; }
  .voyage .ptsforts::after, .programme .ptsforts::after, .avis .ptsforts::after, .photo .ptsforts::after {
    content: "";
    display: table;
    clear: both; }
  .voyage .ptsforts .fb-like, .programme .ptsforts .fb-like, .avis .ptsforts .fb-like, .photo .ptsforts .fb-like {
    margin-top: 20px; }
    .voyage .ptsforts .fb-like.fb_iframe_widget, .programme .ptsforts .fb-like.fb_iframe_widget, .avis .ptsforts .fb-like.fb_iframe_widget, .photo .ptsforts .fb-like.fb_iframe_widget {
      display: block; }

.voyage .vignettes, .programme .vignettes, .avis .vignettes, .photo .vignettes {
  margin-bottom: 15px; }
  .voyage .vignettes::after, .programme .vignettes::after, .avis .vignettes::after, .photo .vignettes::after {
    content: "";
    display: table;
    clear: both; }
  .voyage .vignettes h3, .programme .vignettes h3, .avis .vignettes h3, .photo .vignettes h3 {
    text-transform: uppercase;
    font-size: 12px;
    font-size: 1.2rem;
    color: #925A00;
    margin-bottom: 5px; }
  .voyage .vignettes a:hover, .programme .vignettes a:hover, .avis .vignettes a:hover, .photo .vignettes a:hover {
    opacity: 0.9;
    transition: all 0.2s ease-in-out; }

.voyage .info-right, .programme .info-right, .avis .info-right, .photo .info-right {
  width: 500px;
  height: auto;
  float: right; }

.voyage .video iframe, .programme .video iframe, .avis .video iframe, .photo .video iframe {
  width: 500px; }

.voyage .video p, .programme .video p, .avis .video p, .photo .video p {
  display: none; }

.voyage .video-subst, .programme .video-subst, .avis .video-subst, .photo .video-subst {
  display: block;
  height: auto; }
  .voyage .video-subst img, .programme .video-subst img, .avis .video-subst img, .photo .video-subst img {
    width: 500px;
    height: 100%; }

.voyage .guide--encart, .programme .guide--encart, .avis .guide--encart, .photo .guide--encart {
  width: 100%;
  height: auto;
  background-color: #f0f0f0;
  margin-top: 10px; }
  .voyage .guide--encart::after, .programme .guide--encart::after, .avis .guide--encart::after, .photo .guide--encart::after {
    content: "";
    display: table;
    clear: both; }
  .voyage .guide--encart .guide--photo, .programme .guide--encart .guide--photo, .avis .guide--encart .guide--photo, .photo .guide--encart .guide--photo {
    float: left;
    padding: 10px;
    background-color: #f0f0f0; }
  .voyage .guide--encart .guide--info, .programme .guide--encart .guide--info, .avis .guide--encart .guide--info, .photo .guide--encart .guide--info {
    float: left;
    width: 320px;
    padding: 10px; }
    .voyage .guide--encart .guide--info .title-lev3, .programme .guide--encart .guide--info .title-lev3, .avis .guide--encart .guide--info .title-lev3, .photo .guide--encart .guide--info .title-lev3 {
      margin-bottom: 1.4em; }

.voyage .videominiature iframe, .programme .videominiature iframe, .avis .videominiature iframe, .photo .videominiature iframe {
  width: 247px;
  height: 150px; }

.voyage .videominiature p, .programme .videominiature p, .avis .videominiature p, .photo .videominiature p {
  display: none; }

.voyage .ajout-exp, .programme .ajout-exp, .avis .ajout-exp, .photo .ajout-exp {
  margin: 20px 0 0 0; }
  .voyage .ajout-exp form ul li, .programme .ajout-exp form ul li, .avis .ajout-exp form ul li, .photo .ajout-exp form ul li {
    background: none;
    padding: 0;
    margin-top: 5px; }

#map {
  width: 500px;
  height: 300px;
  float: right;
  background: #fff; }

.avis .text strong {
  display: block;
  margin-bottom: 8px; }

.avis .text .auteur {
  color: #a4be34;
  font-weight: 500; }

a.savoir {
  color: #F1911C;
  text-decoration: none;
  font-size: 0.9em;
  margin-left: 25px; }
  a.savoir:hover {
    color: #925A00; }

.niveaux {
  margin: 15px auto 8px auto; }

.niveaux-list li {
  line-height: 25px; }
  .niveaux-list li .titre-level {
    display: block;
    width: 70px;
    float: left; }
  .niveaux-list li .pictos-levels {
    display: inline-block;
    width: 125px;
    height: 27px;
    margin-left: 10px;
    background: transparent url("/img/pictos-levels.png") no-repeat 0 0; }
  .niveaux-list li .immersion {
    background-position: 0 0; }
    .niveaux-list li .immersion.level-2 {
      background-position: 0 -27px; }
    .niveaux-list li .immersion.level-3 {
      background-position: 0 -54px; }
    .niveaux-list li .immersion.level-4 {
      background-position: 0 -81px; }
    .niveaux-list li .immersion.level-5 {
      background-position: 0 -108px; }
  .niveaux-list li .rusticite {
    background-position: -125px 0; }
    .niveaux-list li .rusticite.level-2 {
      background-position: -125px -27px; }
    .niveaux-list li .rusticite.level-3 {
      background-position: -125px -54px; }
    .niveaux-list li .rusticite.level-4 {
      background-position: -125px -81px; }
    .niveaux-list li .rusticite.level-5 {
      background-position: -125px -108px; }
  .niveaux-list li .autonomie {
    background-position: -250px 0; }
    .niveaux-list li .autonomie.level-2 {
      background-position: -250px -27px; }
    .niveaux-list li .autonomie.level-3 {
      background-position: -250px -54px; }
    .niveaux-list li .autonomie.level-4 {
      background-position: -250px -81px; }
    .niveaux-list li .autonomie.level-5 {
      background-position: -250px -108px; }
  .niveaux-list li .physique {
    background-position: -375px 0; }
    .niveaux-list li .physique.level-2 {
      background-position: -375px -27px; }
    .niveaux-list li .physique.level-3 {
      background-position: -375px -54px; }
    .niveaux-list li .physique.level-4 {
      background-position: -375px -81px; }
    .niveaux-list li .physique.level-5 {
      background-position: -375px -108px; }

a.link-news {
  display: block;
  width: 150px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  position: absolute;
  bottom: 20px;
  right: 45px; }
  a.link-news.link-actu {
    background: #819629;
    color: #2b320e; }
    a.link-news.link-actu:hover {
      background: #3c4613;
      color: #fff; }
  a.link-news.link-exp {
    background: #cd760d;
    color: #553105;
    bottom: 50px; }
    a.link-news.link-exp:hover {
      background: #6d3f07;
      color: #fff; }

/* -- Formulaire Fiche technique -- */
#fiche h3 {
  text-transform: uppercase;
  color: #925A00;
  margin-bottom: 10px; }

#fiche .email {
  width: 470px; }

#fiche ul li {
  display: block;
  height: 30px;
  float: left; }
  #fiche ul li label {
    display: block;
    float: left;
    line-height: 22px;
    background: #a4be34;
    color: #fff;
    width: 80px;
    padding-left: 8px; }
  #fiche ul li input {
    float: left;
    height: 22px;
    outline: none;
    padding-left: 8px; }

#fiche button {
  width: 550px;
  height: 25px;
  padding: 4px;
  background: #fff;
  color: #819629;
  margin-top: 5px;
  border: 1px solid #a4be34;
  border-radius: 4px;
  text-transform: uppercase; }
  #fiche button:hover {
    color: #fff;
    background: #a4be34;
    transition: all 0.2s ease-in-out; }

#fiche p.retour {
  display: block;
  color: #000; }

/* Actualités */
/* Liste */
.listeactu-text {
  text-align: justify; }

.listeactu-date {
  display: inline-block;
  height: 25px;
  line-height: 25px;
  margin-top: 48px;
  padding: 0 10px;
  position: relative;
  background: #925A00;
  font-family: lato, sans-serif;
  font-size: 15px;
  font-size: 1.5rem;
  color: #fff; }
  .listeactu-date::after {
    content: "";
    display: block;
    height: 25px;
    width: 40px;
    position: absolute;
    top: 0;
    right: -30px;
    background: transparent url("/img/borders-32.png") no-repeat -120px -32px; }

.actu-pagination {
  float: right;
  margin: 40px 20px 20px 0;
  clear: both; }
  .actu-pagination::after {
    content: "";
    display: table;
    clear: both; }
  .actu-pagination span {
    display: block;
    float: left;
    width: 50px;
    height: 30px;
    line-height: 30px;
    color: #F1911C; }
  .actu-pagination ul {
    float: left; }
    .actu-pagination ul li {
      list-style-type: none;
      float: left;
      margin: 0 1px; }
    .actu-pagination ul a {
      display: block;
      width: 18px;
      height: 18px;
      line-height: 18px;
      text-align: center;
      border: 1px solid #F1911C;
      color: #F1911C;
      text-decoration: none;
      transition: all 0.2s ease-in-out; }
      .actu-pagination ul a:hover, .actu-pagination ul a:focus, .actu-pagination ul a:active {
        background: #F1911C;
        color: #fff; }
    .actu-pagination ul .selected a {
      border-color: #F1911C;
      background: #F1911C;
      color: #fff;
      cursor: default; }

.img-list-actu {
  display: block;
  width: 225px;
  height: 150px;
  background-color: #ddd;
  text-align: center; }

/* Détail actu */
.detail-actu .content {
  width: 460px;
  float: left;
  margin: 0 20px; }
  .detail-actu .content span.date {
    display: block;
    margin-top: 20px;
    color: #925A00;
    font-weight: 800; }

.image-actu {
  float: right;
  margin-right: 20px;
  width: 480px;
  height: 250px;
  background-color: #ddd;
  text-align: center; }
  .image-actu img {
    max-width: 478px;
    max-height: 248px; }

ul.menu-amarok {
  float: right;
  margin: 0 20px 0 0; }
  ul.menu-amarok li a {
    display: block;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    color: #fff;
    background: #a4be34;
    width: 200px;
    height: 30px;
    line-height: 30px;
    margin-bottom: 1px;
    transition: all 0.25s ease-in-out; }
    ul.menu-amarok li a:hover, ul.menu-amarok li a.selected {
      background: #819629;
      transition: all 0.25s ease-in-out; }

/* Page */
.amarok {
  width: 780px; }
  .amarok .prez {
    margin-bottom: 40px; }
    .amarok .prez img {
      margin-bottom: 10px; }
    .amarok .prez h3 {
      line-height: 20px;
      margin: 25px auto 15px auto; }
    .amarok .prez p {
      line-height: 15px; }
      .amarok .prez p span {
        font-weight: 500;
        color: #a4be34; }
    .amarok .prez ul.colonne {
      position: relative;
      margin-top: 20px; }
      .amarok .prez ul.colonne li.detail {
        list-style-type: none;
        display: block;
        background: none;
        padding-bottom: 20px;
        margin: 0 0 10px 0;
        border-top: 1px solid #F1911C;
        width: 370px;
        float: left; }
        .amarok .prez ul.colonne li.detail p {
          width: 340px; }
        .amarok .prez ul.colonne li.detail span {
          font-weight: 800; }
          .amarok .prez ul.colonne li.detail span.italic {
            font-weight: normal;
            font-style: italic; }
        .amarok .prez ul.colonne li.detail ul li.list-level1 {
          background: none;
          padding: 0;
          margin-left: 20px;
          list-style-type: circle;
          width: 310px; }
        .amarok .prez ul.colonne li.detail ul li.list-level2 {
          margin: 8px 0 0 0;
          line-height: 17px;
          padding-left: 22px;
          list-style-type: none;
          background: transparent url(../../img/puce.png) 0 1px no-repeat;
          width: 340px; }

/* Contact */
.contact .col-left {
  float: left; }
  .contact .col-left .coordonnees {
    margin-bottom: 20px; }
    .contact .col-left .coordonnees h4 {
      color: #a4be34;
      margin: 0; }
    .contact .col-left .coordonnees .text a {
      color: #a4be34;
      text-decoration: none; }
      .contact .col-left .coordonnees .text a:hover {
        color: #819629; }
  .contact .col-left form {
    width: 960px;
    background: #eee;
    padding: 20px; }
    .contact .col-left form::after {
      content: "";
      display: table;
      clear: both; }
    .contact .col-left form ul {
      width: 450px;
      float: left;
      margin-right: 10px; }
      .contact .col-left form ul:last-of-type {
        margin-right: 0;
        margin-left: 10px; }
      .contact .col-left form ul li {
        margin: 5px 0px;
        float: left;
        border: 1px solid transparent; }
        .contact .col-left form ul li:last-of-type {
          margin-bottom: 0; }
        .contact .col-left form ul li label {
          width: 130px;
          height: 35px;
          display: block;
          color: #fff;
          float: left;
          line-height: 35px;
          background: #a4be34;
          padding-left: 15px; }
        .contact .col-left form ul li input {
          width: 318px;
          height: 35px;
          float: left;
          border: none;
          outline: none;
          padding: 10px; }
          .contact .col-left form ul li input:hover, .contact .col-left form ul li input:focus {
            border: 1px solid #a4be34;
            transition: all 0.1s ease-in-out; }
        .contact .col-left form ul li span.mandatory {
          font-size: 10px;
          font-size: 1rem;
          color: #aaa;
          margin: 0; }
          .contact .col-left form ul li span.mandatory sup {
            color: #F1911C; }
        .contact .col-left form ul li.message {
          width: 450px;
          height: 35px;
          display: block;
          color: #fff;
          float: left;
          line-height: 35px;
          background: #a4be34;
          padding-left: 15px;
          margin: 6px 0 0 1px; }
        .contact .col-left form ul li textarea {
          width: 450px;
          height: 128px;
          border: none;
          outline: none;
          padding: 10px;
          margin: 7px 0 1px 0;
          border: 1px solid transparent; }
          .contact .col-left form ul li textarea:hover, .contact .col-left form ul li textarea:focus {
            border: 1px solid #a4be34;
            transition: all 0.1s ease-in-out; }
    .contact .col-left form button {
      width: 450px;
      height: 35px;
      font-size: 12px;
      font-size: 1.2rem;
      background: #efefef;
      color: #F1911C;
      margin: -5px 0 0 10px;
      border: 1px solid #F1911C; }
      .contact .col-left form button:hover {
        color: #fff;
        background: #F1911C; }

.illu {
  width: 580px;
  height: 386px;
  background: transparent url(../../img/contact2.jpg) 0 0 no-repeat;
  float: right;
  margin-right: 20px; }

/* 
########    #######   ##     ##  ####  ########   ########   
##         ##     ##  ##     ##   ##   ##     ##  ##         
##         ##     ##  ##     ##   ##   ##     ##  ##         
######     ##     ##  ##     ##   ##   ########   ######     
##         ##  ## ##  ##     ##   ##   ##         ##         
##         ##    ##   ##     ##   ##   ##         ##         
########    ##### ##   #######   ####  ##         ########   
*/
.equipe--title {
  margin-bottom: 0.5em !important; }

.equipe--groupe {
  margin-bottom: 4em; }
  .equipe--groupe:last-of-type {
    margin-bottom: 2em; }
  .equipe--groupe .title-lev2 {
    margin-top: 12px;
    text-transform: none; }
  .equipe--groupe .equipe-cell {
    width: 300px; }

.equipe--title-lev3 {
  margin: 1.2em 0; }

.equipe--intro {
  margin-bottom: 0.5em; }

.equipe--photo {
  height: auto;
  width: auto; }
  .equipe--photo img {
    height: 100%;
    width: 100%; }

.equipe--fonction {
  display: block;
  margin: 0 0 2em 0;
  font-size: 1.3rem;
  position: relative;
  max-width: 240px; }
  .equipe--fonction .text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%; }

/* Newsletter */
.newsletter form {
  width: 960px;
  background: #eee;
  padding: 20px; }
  .newsletter form::after {
    content: "";
    display: table;
    clear: both; }
  .newsletter form ul {
    width: 660px;
    margin: 0 auto; }
    .newsletter form ul::after {
      content: "";
      display: table;
      clear: both; }
    .newsletter form ul li {
      width: 660px;
      margin: 5px 0px;
      float: left; }
      .newsletter form ul li:last-of-type {
        margin-bottom: 0; }
      .newsletter form ul li label {
        width: 130px;
        height: 35px;
        display: block;
        color: #fff;
        float: left;
        line-height: 35px;
        background: #a4be34;
        padding-left: 15px; }
      .newsletter form ul li input {
        width: 530px;
        height: 35px;
        float: left;
        border: none;
        outline: none;
        padding: 10px;
        border: 1px solid transparent; }
        .newsletter form ul li input:hover, .newsletter form ul li input:focus {
          border: 1px solid #a4be34;
          transition: all 0.2s ease-in-out; }
      .newsletter form ul li span.mandatory {
        font-size: 10px;
        font-size: 1rem;
        color: #aaa;
        margin: 0; }
        .newsletter form ul li span.mandatory sup {
          color: #F1911C; }
  .newsletter form button {
    display: block;
    width: 660px;
    height: 35px;
    font-size: 12px;
    font-size: 1.2rem;
    background: #efefef;
    color: #F1911C;
    margin: 10px auto 5px auto;
    border: 1px solid #F1911C; }
    .newsletter form button:hover {
      color: #fff;
      background: #F1911C; }

/* Footer */
.footer-main {
  width: 1000px;
  margin: auto;
  margin-top: 20px;
  padding: 15px 20px 10px 20px;
  position: relative;
  background: #e3e3e3;
  font-size: 0.8em; }
  .footer-main::after {
    content: "";
    display: table;
    clear: both; }

.footer-container:before, .footer-container:after {
  content: "";
  display: block;
  width: 89px;
  height: 110px;
  position: absolute;
  top: -20px;
  left: -89px;
  background: transparent url("/img/bg-footer.png") 0 0 no-repeat; }

.footer-container:after {
  top: auto;
  left: auto;
  bottom: 20px;
  right: -89px;
  background-position: -10px -120px; }

.footer-block {
  width: 180px;
  margin-right: 20px;
  float: left;
  font-family: lato, sans-serif; }
  .footer-block p + ul {
    margin-top: 10px; }
  .footer-block li {
    padding: 1px 0;
    text-transform: uppercase; }
  .footer-block a {
    color: #000;
    text-decoration: none;
    transition: all 0.2s ease-in-out; }
    .footer-block a:hover, .footer-block a:focus, .footer-block a:active {
      color: #fff;
      background: #000; }
  .footer-block h6 {
    font-size: 11px;
    font-size: 1.1rem; }
  .footer-block.footer-destination {
    width: 245px; }
    .footer-block.footer-destination .destination-container {
      display: flex; }
      .footer-block.footer-destination .destination-container ul {
        margin-right: 10px; }

.logo-tourisme-responsable {
  display: block;
  width: 103px;
  height: 61px;
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent url("/img/logo-tourisme-responsable.png") no-repeat 0 0;
  text-indent: -9999px; }

.logo-terra-scientifica {
  width: 130px;
  height: auto;
  position: absolute;
  right: 170px;
  top: 110px; }

.logo-jama {
  width: 130px;
  height: auto;
  position: absolute;
  right: 20px;
  top: 100px; }

.logo-credit-cooperatif, .logo-caisse-epargne {
  width: 120px;
  height: auto;
  position: absolute;
  right: 20px;
  top: 217px; }

.logo-cheque-vacances {
  width: 120px;
  height: auto;
  position: absolute;
  right: 150px;
  top: 210px; }

.credit-photo {
  position: absolute;
  bottom: 5px;
  right: 20px;
  color: #999; }

.credit {
  display: block;
  text-align: center;
  font-size: 10px;
  font-size: 1rem;
  margin: 5px 0 15px 0;
  color: #888;
  line-height: 15px;
  margin: 10px auto; }
  .credit a {
    color: #888; }

/* 
########   ##          #######    ######          ######    ########    #######   
##     ##  ##         ##     ##  ##    ##        ##    ##   ##         ##     ##  
##     ##  ##         ##     ##  ##              ##         ##         ##     ##  
########   ##         ##     ##  ##               ######    ######     ##     ##  
##     ##  ##         ##     ##  ##                    ##   ##         ##     ##  
##     ##  ##         ##     ##  ##    ##        ##    ##   ##         ##     ##  
########   ########    #######    ######          ######    ########    #######   
 */
.seo-bloc {
  background-color: #f5f5f5; }
  .seo-bloc .seo-title {
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: bold;
    margin: 10px 0; }
  .seo-bloc .seo-content {
    font-size: 11px;
    font-size: 1.1rem; }
    .seo-bloc .seo-content p {
      margin: 10px 0;
      text-align: justify; }
    .seo-bloc .seo-content ul {
      list-style: disc;
      margin-left: 20px; }
    .seo-bloc .seo-content a {
      color: #000;
      text-decoration: none; }
    .seo-bloc .seo-content a:hover {
      color: #F49200; }

[data-link] {
  cursor: pointer; }

.membre-photo img {
  max-width: 100%;
  height: auto; }

.slider-container {
  width: 690px;
  float: left;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-right: -20px; }
  .slider-container.full-width {
    width: 100%;
    height: auto; }
  .slider-container .slick-track {
    height: 460px; }
  .slider-container .slick-prev,
  .slider-container .slick-next {
    position: absolute;
    top: 20px;
    z-index: +1;
    font-size: 0;
    height: 21px;
    width: 21px;
    border: none;
    outline: 0; }
    .slider-container .slick-prev:hover,
    .slider-container .slick-next:hover {
      background-color: #000; }
  .slider-container .slick-prev {
    right: 42px;
    background: grey url(/img/slider/arrow-prev.png) 0 0 no-repeat; }
  .slider-container .slick-next {
    right: 20px;
    background: grey url(/img/slider/arrow-next.png) 0 0 no-repeat; }
  .slider-container .slick-slide:focus {
    outline: none; }

.membre-galerie {
  display: flex;
  position: relative;
  flex-wrap: wrap; }
  .membre-galerie .item {
    flex-basis: 25%;
    padding: 0 3px 3px 0; }
    .membre-galerie .item img {
      width: 100%; }

#fancybox-thumbs {
  position: fixed;
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: 8050; }

#fancybox-thumbs.bottom {
  bottom: 2px; }

#fancybox-thumbs.top {
  top: 2px; }

#fancybox-thumbs ul {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0; }

#fancybox-thumbs ul li {
  float: left;
  padding: 1px;
  opacity: 0.5; }

#fancybox-thumbs ul li.active {
  opacity: 0.75;
  padding: 0;
  border: 1px solid #fff; }

#fancybox-thumbs ul li:hover {
  opacity: 1; }

#fancybox-thumbs ul li a {
  display: block;
  position: relative;
  overflow: hidden;
  border: 1px solid #222;
  background: #111;
  outline: none; }

#fancybox-thumbs ul li img {
  display: block;
  position: relative;
  border: 0;
  padding: 0; }

/*! fancyBox v2.1.3 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top; }

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020; }

.fancybox-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }

.fancybox-opened {
  z-index: 8030; }

.fancybox-opened .fancybox-skin {
  -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5); }

.fancybox-outer, .fancybox-inner {
  position: relative; }

.fancybox-inner {
  overflow: hidden; }

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch; }

.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap; }

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%; }

.fancybox-image {
  max-width: 100%;
  max-height: 100%; }

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
  background-image: url("../../img/fancy/fancybox_sprite.png"); }

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060; }

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url("../../img/fancy/fancybox_loading.gif") center center no-repeat; }

.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040; }

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url("../../img/fancy/blank.gif");
  /* helps IE */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 8040; }

.fancybox-prev {
  left: 0; }

.fancybox-next {
  right: 0; }

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden; }

.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px; }

.fancybox-next span {
  right: 10px;
  background-position: 0 -72px; }

.fancybox-nav:hover span {
  visibility: visible; }

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important; }

/* Overlay helper */
.fancybox-lock {
  overflow: hidden; }

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: url("../../img/fancy/fancybox_overlay.png"); }

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0; }

.fancybox-lock .fancybox-overlay {
  /*overflow: auto;*/
  overflow-y: scroll; }

/* Title helper */
.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050; }

.fancybox-opened .fancybox-title {
  visibility: visible; }

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center; }

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(0, 0, 0, 0.8);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap; }

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff; }

.fancybox-title-inside-wrap {
  padding-top: 10px; }

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8); }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.formError {
  position: absolute;
  top: 300px;
  left: 300px;
  width: 150px;
  padding-bottom: 15px;
  display: block;
  z-index: 5000; }

.formError .formErrorContent {
  text-align: left;
  width: 100%;
  background-color: #F1911C;
  color: #fff;
  font-family: tahoma;
  font-size: 10px;
  padding: 2px;
  border-radius: 6px;
  /*-moz-border-radius: 6px;
	-webkit-border-radius: 6px;*/ }

.formError .formErrorArrow {
  position: absolute;
  bottom: 0;
  left: 20px;
  width: 15px;
  height: 15px; }

.formError .formErrorArrow div {
  font-size: 0px; }

.formError .formErrorArrow .line10 {
  width: 15px;
  height: 1px;
  background: #F1911C;
  margin: 0 auto;
  font-size: 0px;
  display: block; }

.formError .formErrorArrow .line9 {
  width: 13px;
  height: 1px;
  background: #F1911C;
  margin: 0 auto;
  display: block; }

.formError .formErrorArrow .line8 {
  width: 11px;
  height: 1px;
  background: #F1911C;
  margin: 0 auto;
  display: block; }

.formError .formErrorArrow .line7 {
  width: 9px;
  height: 1px;
  background: #F1911C;
  margin: 0 auto;
  display: block; }

.formError .formErrorArrow .line6 {
  width: 7px;
  height: 1px;
  background: #F1911C;
  margin: 0 auto;
  display: block; }

.formError .formErrorArrow .line5 {
  width: 5px;
  height: 1px;
  background: #F1911C;
  margin: 0 auto;
  display: block; }

.formError .formErrorArrow .line4 {
  width: 3px;
  height: 1px;
  background: #F1911C;
  margin: 0 auto;
  display: block; }

.formError .formErrorArrow .line3 {
  width: 1px;
  height: 1px;
  background: #F1911C;
  margin: 0 auto;
  display: block; }
