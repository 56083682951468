#fancybox-thumbs {
	position: fixed;
	left: 0;
	width: 100%;
	overflow: hidden;
	z-index: 8050;
}

#fancybox-thumbs.bottom {
	bottom: 2px;
}

#fancybox-thumbs.top {
	top: 2px;
}

#fancybox-thumbs ul {
	position: relative;
	list-style: none;
	margin: 0;
	padding: 0;
}

#fancybox-thumbs ul li {
	float: left;
	padding: 1px;
	opacity: 0.5;
}

#fancybox-thumbs ul li.active {
	opacity: 0.75;
	padding: 0;
	border: 1px solid #fff;
}

#fancybox-thumbs ul li:hover {
	opacity: 1;
}

#fancybox-thumbs ul li a {
	display: block;
	position: relative;
	overflow: hidden;
	border: 1px solid #222;
	background: #111;
	outline: none;
}

#fancybox-thumbs ul li img {
	display: block;
	position: relative;
	border: 0;
	padding: 0;
}