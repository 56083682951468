html { font-size: 62.5%; }

$basefont: verdana, sans-serif;
$altfont: lato, sans-serif;
$terfont: "Lobster Two", serif;

$imgPath: '/img/';

$white: #fff;

$bg:        $white;
$color1:    #a4be34; // vert
$color2:    #F1911C; // orange
//$color3:    #845a15; // marron
$color3:    #925A00; // marron
$color4:    #e3e3e3; // gris clair

$text:      #000;
$textlight: $white;

$fullwidth: 1000px;
$gutter: 20px;