* {
	box-sizing: border-box;
}

body {
	background: transparent no-repeat center top;
	// background-image: url('../img/bg-body-2.jpg');
	font-family: $basefont;
	@include font-size(12);
	line-height: 1.2;
	color: $text;
}

/* FCK (back-office) */
.fck {

	h2 {
		@include font-size(16);
		font-weight: 500;
		color: $color2;
		margin: 1.4em 0 0.8em 0;
		text-transform: uppercase;

		&:first-of-type {
			margin-top: 0.2em;
		}
	}

	p {
		font-family: $basefont;
		@include font-size(12);
		line-height: 1.2;
		color: $text;
	}

	a.lien-catalogue {
		font-family: lato, sans-serif;
		text-transform: uppercase;
		text-decoration: none;
		color: $color2;
		font-size: 16px;
		font-weight: 900;

		&:hover {
			color: $color3;
			transition: all 0.2s ease-in-out;
		}
	}
}

/* Layout */
.wrapper {
	@include clearfix;
	width: $fullwidth;
	margin: 0 auto;
}

.sidebar {
	width: 340px;
	float: left;
}

.content-with-sidebar {
	width: $fullwidth - 340px - $gutter;
	margin-right: $gutter;
	float: left;
}

.padding {
	padding: $gutter;
}

.padding-horizontal {
	padding: 0 $gutter;
}

.padding-vertical {
	padding: $gutter 0;
}

.no-padding {
	padding: 0;
}

.g-row {
	//width: 660px;
	@include clearfix;
	margin-top: $gutter;
	padding: 0 $gutter;

	&.with-slider {
		width: 660px;

		.g-cell:last-of-type {
			margin-right: $gutter;
		}
	}

	&.padding {
		padding: 10px $gutter;
	}

	&.no-padding {
		padding: 0;
	}

	&.color2 {
		background: $color2;
		width: 1000px;
		// margin-left: - $gutter;
	}
}

.g-cell {
	@include clearfix;
	margin-right: $gutter;
	float: left;
	position: relative;

	&:last-of-type {
		margin-right: 0;
	}
}

$g-200: 200px;
.g-200-1 {
	width: 200px;
}

.g-200-2 {
	width: 400px + $gutter;
}

.g-200-3 {
	width: 600px + (2 * $gutter);
}

$g-225: 225px;
.g-225-1 {
	width: $g-225;
}

.g-225-2 {
	width: 2 * $g-225 + $gutter;
}

.g-225-3 {
	width: 3 * $g-225 + 2 * $gutter;
}

.g-225-4 {
	width: 4 * $g-225 + 3 * $gutter;
}

.g-mb {
	margin-bottom: 30px;
}


.border-bottom {
	// width: 1000px;
	@include clearfix;
	padding-bottom: $gutter;
	border-bottom: 1px solid $color2;

	.g-row:last-of-type & {
		padding-bottom: 0;
		border: none;
	}
}

a.g-row {
	display: block;

	color: $text;

	transition: all 0.2s ease-in-out;

	&:hover, &:focus, &:active {
		background: $color4;
	}
}

.asterix {
	position: absolute;
	right: 30px;
	bottom: -15px;
	color: $color3;
	@include font-size(10);
}

.asterixdetail {
	color: $color3;
	@include font-size(10);
	margin-top: 5px;
}

.mt-100 {
	margin: 80px 0 -15px 0;
}

/* Titles */
.title-lev1,
.title-lev2,
.title-lev3 {
	font-family: $altfont;
	font-weight: 300;
	text-transform: uppercase;
	color: $color3;

	strong {
		font-weight: 900;
	}

	a {
		color: $color3;
		text-decoration: none;

		transition: all 0.2s ease-in-out;

		&:hover, &:focus, &:active {
			color: $color2;
		}
	}
}

.title-lev1 {
	margin: 0 0 $gutter 0;
	@include font-size(30);

	&.alt {
		background-color: $color3;
		color: $textlight;
		padding-top: $gutter / 2;
		padding-bottom: $gutter / 2;
	}
}

.title-lev2 {
	margin: 0 0 ($gutter / 2) 0;
	@include font-size(20);
}

.title-lev3 {
	@include font-size(16);
	font-weight: 900;
	color: $color2;
}

.title-lev6 {
	margin-bottom: 2px;
	text-transform: uppercase;
	font-weight: bold;

}

.title-brown {
	display: inline-block;
	margin-right: 2 * $gutter;
	padding: 0 $gutter;
	line-height: 32px;
	float: left;
	position: relative;

	background: $color3;

	font-family: $altfont;
	@include font-size(16);
	font-weight: 700;
	color: $textlight;
	text-transform: uppercase;

	&:after {
		display: block;
		content: "";
		width: 40px;
		height: 32px;
		position: absolute;
		top: 0;
		right: -40px;

		background: transparent url('#{$imgPath}borders-32.png') no-repeat 0 -32px;
	}

	&.alt {
		&:after {
			background-position: -80px -32px;
		}
	}
}

/* Typography */
p {
	margin-bottom: 1em;

	&:last-of-type {
		margin-bottom: 0;
	}
}

strong {
	font-weight: bold;
}

em {
	font-style: italic;
}

ul {
	margin-bottom: 1em;
}

.btn {
	display: inline-block;
	height: 26px;
	margin-top: 3px;

	background: $bg;
	border: none;

	font-family: $altfont;
	font-weight: 700;
	@include font-size(16);
	text-transform: uppercase;

	color: $color3;
	cursor: pointer;

	border-radius: 5px;

	transition: all 0.2s ease-in-out;

	&:hover, &:focus, &:active {
		background: $color4;
		color: $text;
	}
}

/* Navigation */
.nav-main {
	display: block;
	width: 100%;
	height: 40px;
	z-index: 10;

	position: absolute;
	right: 0;
	bottom: 0;

	// &:before,
	// &:after {
	// 	content: "";
	// 	display: none;
	// 	width: 20px;
	// 	height: 32px;
	// 	position: absolute;
	// 	top: 0;
	// 	left: -20px;

	// 	background: transparent url('#{$imgPath}borders-32.png') no-repeat -39px 0;
	// }

	// &:after {
	// 	width: 30px;
	// 	left: auto;
	// 	right: -30px;

	// 	background-position: 0 0;
	// }

	& > ul {
		text-align: right;
		padding: 0;
		position: relative;

		display: flex;
		justify-content: flex-end;

		> li {
			display: inline-block;
			line-height: 40px;

			.first-level {
				display: block;
				padding: 0 9px;

				font-family: $altfont;
				font-weight: 700;
				color: $color3;
				color: darken($color3, 5%);
				text-transform: uppercase;
				text-decoration: none;
				text-align: right !important;
				letter-spacing: 0.05em;

				transition: all 0.2s ease-in-out;

				&:hover, &:focus, &:active, &.current, &.selected {
					color: $color3;
					cursor: pointer;
				}
			}

			.subnav-container {
				@include clearfix();
				width: 1000px;
				min-height: 420px;
				position: absolute;
				top: 100%;
				left: 0;
				margin: 0;
				padding: 10px 15px;
				text-align: left;
				background-color: $color1;

				visibility: hidden;
				opacity: 0;
				// transition: top 0.1s ease-in-out, opacity 0.2s ease-in-out;

				.no-touch & {
					transition: top 0.1s ease-in-out, opacity 0.2s ease-in-out, visibility 0.1s ease-in-out;
				}

				&:before,
				&:after {
					display: block;
					content: '';
					position: absolute;
				}

				&:before {
					width: 209px;
					height: 224px;
					background: transparent url('../../img/alt-quicksearch-dropdown.png') no-repeat 0 0;
					background-size: 209px 224px;

					bottom: 0;
					right: 0;

					opacity: 0.8;
				}

				&:after {
					width: 1000px;
					height: 18px;
					background: transparent url('../../img/bottom-subnav-dropdown.png') no-repeat 0 0;
					background-size: 1000px 18px;

					top: 100%;
					left: 0;
				}

				&.subnav-container--destination {
					padding-left: 414px;
				}

				ul.subnav {
					width: 100%;
					padding: 10px;

					& > li {
						line-height: 2em;

						a {
							display: block;
							color: $white;
							text-decoration: none;

							transition: color 0.3s ease-in-out;

							&:hover {
								color: $color3;
							}
						}
					}

					&.subnav--picto {
						@include center();
						text-align: center;

						& > li {
							display: inline-grid;
							width: 220px;
							text-align: center;

							img.item__picto {
								width: 100px;
								height: auto;
							}

							span.item__title {
								display: block;
								max-width: 80%;
								margin: 10px auto;
								@include font-size(18);
								line-height: 1.3em;
							}

							p.item__desc {
								max-width: 85%;
								margin: 0 auto;
								font-family: $altfont;
								@include font-size(13);
								line-height: 1.4em;
								font-weight: 300;
								color: rgba($textlight, 0.9)
							}
						}
					}

					&.subnav--text {
						@include center();
						text-align: center;

						li {

							&:after {
								display: block;
								content: "";
								margin: 0 auto;
								height: 1px;
								width: 120px;
								background-color: rgba($white, 0.2);
							}

							&:last-of-type:after {
								opacity: 0;
							}

							a {
								@include font-size(18);
								line-height: 2.2em;
							}
						}
					}

					&.subnav--destination {
						@include clearfix();
						padding-top: 40px;

						li {
							text-align: left;

							&:after {
								margin: 0;
							}

							&.subnav--destination--col {
								float: left;
								margin-left: 60px;

								&:after {
									display: none;
								}
							}

							&.destination {

								&:after {
									display: block;
									content: "";
									height: 1px;
									// width: 100px;
									background-color: rgba($white, 0.2);
								}

								&:last-of-type:after {
									opacity: 0;
								}

								a {
									@include font-size(14);
									line-height: 2em;
								}
							}

							span.region {
								color: $white;
								text-transform: uppercase;
								@include font-size(16);
								font-weight: 900;

								display: inline-block;
								margin-bottom: 10px;
							}
						}
					}
				}
			}
		}
	}
}

.nav-main > ul > li.is-open {
	background-color: $color1;

	> .subnav-container {
		visibility: visible;
		// top: 100%;
		opacity: 1;
		// transition: top 0.2s ease-in-out, opacity 0.4s ease-in-out;
	}
}

.nav-social {
	position: absolute;
	top: 20px;
	right: 14px;

	li {
		display: inline-block;
		position: relative;

		.tiret &::after {
			content: " - ";
		}

		// &::after {
		// 	content: " - ";
		// }

		// &:last-of-type {
		// 	&::after {
		// 		content: "";
		// 	}
		// }
	}

	a {
		display: block;
		color: $text;
		text-decoration: none;
		line-height: 20px;

		&.linktext {
			line-height: 20px;

			&:hover {
				color: $color2;
			}
		}

		&.tiret {

			&::after {
				content: " -";
			}
		}

		&.icon {
			padding-left: 20px;
			width: 15px;
			height: 15px;
		}

		&.facebook {
			background: transparent url(../../img/icon-facebook.png) 0 0 no-repeat;

			&:hover, &:focus, &:active {
				background-position: 0 -15px;
				color: #364E8C;
			}
		}

		&.googleplus {
			background: transparent url(../../img/icon-googleplus.png) 0 0 no-repeat;

			&:hover, &:focus, &:active {
				background-position: 0 -15px;
				color: #d6492f;
			}
		}

		&.instagram {
			background: transparent url(../../img/icon-instagram.png) 0 0 no-repeat;

			&:hover, &:focus, &:active {
				background-position: 0 -15px;
				color: #ec308d;
			}
		}

		&.vimeo {
			background: transparent url(../../img/icon-vimeo.png) 0 0 no-repeat;

			&:hover, &:focus, &:active {
				background-position: 0 -15px;
				color: #00ADEF;
			}
		}

		&.youtube {
			width: 40px;
			padding-left: 44px;
			background: transparent url(../../img/icon-youtube.png) 0 0 no-repeat;

			&:hover, &:focus, &:active {
				background-position: 0 -15px;
				color: #ec2027;
			}
		}

		transition: all 0.2s ease-in-out;
	}

	span {
		margin: 0 5px 0 0;
		top: 0px;
		left: 0;
		@include font-size(16);

		position: absolute;
	}
}

.nav-items {
	display: inline-block;

	li {
		float: left;
		margin-right: $gutter;

		&:last-of-type {
			margin-right: 0;
		}

		a {
			display: inline-block;
			line-height: 32px;

			font-family: $altfont;
			font-weight: 900;
			text-transform: uppercase;
			text-decoration: none;
			color: $color3;

			transition: all 0.2s ease-in-out;

			&:hover, &:focus, &:active, &.current {
				color: $color2;
			}
		}
	}
}


/* Formulaire expérience */
.ajout-exp {
	margin: 40px 0 0 20px;
	border-top: 1px solid $color2;
	padding-top: 15px;

	form {
		@include clearfix;
		width: 960px;
		background: #eee;
		padding: 20px;

		h2 {
			padding-bottom: 10px;
		}

		ul {
			width: 960px;
			float: left;

			li {
				margin: 5px 0px;
				float: left;
				border: 1px solid transparent;

				label {
					width: 250px;
					height: 35px;
					display: block;
					color: $textlight;
					float: left;
					line-height: 35px;
					background: $color1;
					padding: 0 15px;
				}

				input.champ {
					width: 670px;
					height: 35px;
					float: left;
					border: none;
					outline: none;
					padding: 10px;

					&:hover, &:focus {
						border: 1px solid $color1;
						transition: all 0.2s ease-in-out;
					}
				}
			}
		}

		ul.detail {
			margin-top: -10px;

			li.message {
				width: 920px;
				height: 35px;
				display: block;
				color: $textlight;
				float: left;
				line-height: 33px;
				background: $color1;
				padding-left: 15px;
				margin-left: 1px;
			}

			textarea {
				width: 920px;
				height: 150px;
				border: none;
				outline: none;
				padding: 10px 15px;
				float: left;
				border: 1px solid transparent;

				&:hover, &:focus {
					border: 1px solid $color1;
					transition: all 0.2s ease-in-out;
				}
			}
		}

		button {
			width: 920px;
			height: 35px;
			@include font-size(12);
			background: #efefef;
			color: $color2;
			border: 1px solid $color2;
			margin: 0;

			&:hover {
				color: $textlight;
				background: $color2;
			}
		}
	}
}

/* Modules */
.header-main {
	height: 95px;
	position: relative;

	&:after {
		display: block;
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;

		background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);

		opacity: 0.7;
	}

	&:before {
		display: block;
		content: "";
		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;

		background-color: rgba(#789A00, 0.85);
		opacity: 0;

		// transform-origin: top center;
		// transform: translate3d(0, -40px, 0);
		// transition: transform 0.1s ease-in-out, opacity 0.1s ease-in-out;
	}

	&.dropdown {

		&:before {
			// transform: translate3d(0, 0, 0);
			opacity: 1;
			// transition: transform 0.2s ease-in-out, opacity 0.4s ease-in-out;
		}

		.first-level {
			transition: none;
			color: $textlight;

			&:hover, &:focus, &:active, &.current, &.selected {
				color: $textlight;
			}
		}

		.logo-amarok {
			background: transparent url('#{$imgPath}logo-amarok-white.png') no-repeat center center;
		}
	}
}

.logo-amarok {
	display: block;
	width: 200px;
	height: 95px;
	float: left;
	position: relative;
	z-index: 20;

	background: transparent url('#{$imgPath}logo-amarok.png') no-repeat center center;

	text-indent: -9999px;
}

.intro {
	margin-bottom: 2 * $gutter;

	font-family: $terfont;
	@include font-size(22);
	font-weight: 400;
	font-style: italic;

	color: darken($color4, 30%);
}

/* Player home */
.home-banner {
	position: relative;
	width: 1000px;
	height: 420px;
}

.nav-quicksearch {
	display: block;
	width: 209px;
	height: 224px;
	padding: 65px 10px 10px 55px;
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 3;

	background: transparent url('#{$imgPath}bg-quicksearch.png') no-repeat 0 0;

	h3 {
		text-transform: uppercase;
		color: $color2;
		font-family: $altfont;
		font-weight: 900;
		@include font-size(14);
		margin-bottom: 10px;
	}

	ul li {

		select {
			width: 135px;
			margin-bottom: 5px;
		}

		button {
			width: 135px;
			@include font-size(14);
			background: $color2;
			color: #fff;
			margin-top: 7px;

			&:hover {
				color: $color2;
			}
		}
	}
}

/***** POPUP vidéo *****/
#popup {
	position: fixed;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	background: rgba(0, 0, 0, 0.8);
	z-index: 1002;

	.video {
		width: 720px;
		height: 420px;
		position: absolute;
		top: 50%;
		margin-top: -210px;
		left: 50%;
		margin-left: -360px;
		background: #000;
		background: rgba(255, 255, 255, 0.3);
		padding: 20px;

		iframe {
			width: 100%;
			height: 100%;
		}

		p {
			display: none;
		}

		a.close {
			color: #aaa;
			text-decoration: none;
			position: absolute;
			top: 5px;
			right: 8px;

			&:hover {
				color: #ddd;
			}
		}
	}
}


/***** Slideshow (home) *****/
#container {
	width: 1000px;
	height: 420px;
	position: relative;
}

#slides {
	position: absolute;
	z-index: 1;
}

.slides_container {
	width: 1000px;
	overflow: hidden;
	position: relative;
	display: none;

	a.slideshow {
		width: 1000px;
		height: 420px;
		display: block;

		.slideshow-legend {
			display: block;
			width: 1000px;
			padding: 15px 20px;
			position: absolute;
			bottom: 0;
			left: 0;

			background: #000;
			background: rgba(#000, 0.2);

			color: $textlight;

			h2 {
				margin-bottom: 5px;
				font-family: $altfont;
				@include font-size(30);
				font-weight: 300;
				text-transform: uppercase;

				strong {
					font-weight: 900;
				}
			}

			p {
				width: 640px;
			}

			img {
				display: block;
				width: 1000px;
			}
		}
	}
}

/***** Slideshow (home) *****/
#container {
	width: 1000px;
	height: 420px;
	position: relative;
}

#slides {
	position: absolute;
	z-index: 1;
}

.slides_container {
	width: 1000px;
	overflow: hidden;
	position: relative;
	display: none;

	a.slideshow {
		width: 1000px;
		height: 420px;
		display: block;

		.slideshow-legend {
			display: block;
			width: 1000px;
			padding: 15px 20px;
			position: absolute;
			bottom: 0;
			left: 0;

			background: #000;
			background: rgba(#000, 0.2);

			color: $textlight;

			h2 {
				margin-bottom: 5px;
				font-family: $altfont;
				@include font-size(30);
				font-weight: 300;
				text-transform: uppercase;

				strong {
					font-weight: 900;
				}
			}

			p {
				width: 640px;
			}

			img {
				display: block;
				width: 1000px;
			}
		}
	}
}

/* Next/prev button */
#slides .next, #slides .prev {
	position: absolute;
	top: 20px;
	right: 20px;
	width: 21px;
	height: 21px;
	display: block;
	z-index: 101;

	&:hover {
		background: #000;
		transition: all 0.2s ease-in-out;
		// background: darken($color1,30%);
	}
}

#slides .prev {
	right: 42px;
}

/* Pagination */
.pagination {
	position: absolute;
	bottom: 20px;
	right: 20px;
	margin: 0;
	display: block;
	z-index: 20;
	background: #fff;
	background: rgba(#fff, 0.6);
	padding: 10px;

	li {
		float: left;
		margin: 0 2px;
		list-style: none;

		a {
			display: block;
			width: 12px;
			height: 0;
			padding-top: 12px;
			background-image: url(../../img/slider/pagination.png);
			background-position: 0 0;
			float: left;
			overflow: hidden;
		}

		&.current a {
			background-position: 0 -12px;
		}
	}
}


.arrows {
	float: left;
	margin-right: 1px;
	height: 32px;
	z-index: 201;

	a {
		display: block;
		float: left;
		width: 32px;
		height: 32px;

		&.prev-minislide {
			background: transparent url(../../img/arrow-prev2.png) 0 0 no-repeat;
			margin-right: 1px;

			&:hover {
				// background-position: 0 -32px;
				opacity: 0.8;
				transition: all 0.3s ease-in-out;
			}
		}

		&.next-minislide {
			background: transparent url(../../img/arrow-next2.png) 0 0 no-repeat;

			&:hover {
				// background-position: 0 -32px;
				opacity: 0.8;
				transition: all 0.3s ease-in-out;
			}
		}
	}
}

.travel-item {
	@include clearfix;
	display: block;
	text-decoration: none;

	.travel-destination,
	.travel-title {
		margin: 0;
		font-family: $altfont;
		@include font-size(13);
		font-weight: 800;
		text-transform: uppercase;
		color: $text;

		.color2 & {
			color: $textlight;
		}

		transition: all 0.2s ease-in-out;
	}

	.travel-title {
		display: block;
		margin-bottom: 4px;
		min-height: 25px;

		@include font-size(10);
		font-weight: 300;
	}

	img {
		height: 150px;
		width: 100%;

		transition: all 0.2s ease-in-out;
	}

	&:hover, &:focus, &:active {
		.travel-destination {
			color: $color3;
		}

		img {
			opacity: 0.8;
		}
	}
}

.splash-item {
	display: block;
	width: 365px;
	height: 225px;
	margin-top: $gutter;
	padding: $gutter;
	position: relative;

	background: transparent url('#{$imgPath}bg-splash.png') no-repeat 0 0;

	&.alt {
		background-position: 0 -236px;
	}

	a {
		@include clearfix;
		display: block;
		color: $text;
		text-decoration: none;
		height: 130px;

		transition: all 0.2s ease-in-out;

		&:hover, &:focus, &:active {
			color: lighten($text, 30%);

			img {
				opacity: 0.8;
			}
		}
	}

	.splash-title {
		margin-bottom: $gutter / 2;
		font-family: $altfont;
		@include font-size(16);
		font-weight: 900;
		text-transform: uppercase;

		color: $textlight;

		transition: all 0.2s ease-in-out;
	}

	.small-title {
		margin-bottom: $gutter / 2;
		margin-right: $gutter;
		font-weight: bold;
	}

	img {
		max-width: 130px;
		max-height: 130px;
		margin: 0 $gutter $gutter 0;
		float: left;

		transition: all 0.2s ease-in-out;
	}

	.meta-light {
		@include font-size(10);
		color: $textlight;
	}

	p {
		margin-right: $gutter;
	}


	&:hover, &:focus, &:active {

	}
}

/* Filter Nav */
.header-filter {
	padding: $gutter;

	.title-lev1 {
		margin-bottom: 0;
	}
}

.nav-filter {
	@include clearfix;
	height: 32px;
	line-height: 32px;
	margin: 0 $gutter;
	position: relative;

	background: $color3;

	&::before,
	&::after {
		display: block;
		content: "";
		width: 40px;
		height: 32px;
		position: absolute;
		top: 0;
		left: -18px;

		background: transparent url('#{$imgPath}borders-32.png') no-repeat -40px -32px;
	}

	&::after {
		left: auto;
		right: -40px;

		background-position: 0 -32px;
	}


	li {
		width: ($fullwidth - (4 * $gutter)) / 5;
		float: left;
	}

	select {
		width: 90%;
	}

	.btn {
		width: 90%;
	}

	.title-lev1 + & {
		margin-top: 1em;
	}
}

.voyage-liste {
	.g-225-3 {
		height: 170px;
	}

	.travel-list-meta {
		margin-bottom: 0;
		position: absolute;
		left: 0;
		bottom: 0;
	}

	.travel-price {
		position: absolute;
		right: 0;
		bottom: 0;
	}
}

.travel-label {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	display: block;
	line-height: 22px;
	background-color: $color1;
	font-size: 10px;
	color: #fff;
	text-transform: uppercase;

	img {
		float: left;
		margin: 0 5px 0 2px;
	}
}

.travel-info {
	padding: 20px 20px 25px 20px;
	background-color: #f5f5f5;

	&.travel-info--intro {
		margin: 20px 0;
	}

	&.travel-info--outro {
		margin: 20px;
	}
}

.travel-price {
	display: inline-block;
	height: 32px;
	line-height: 32px;
	margin-right: 40px;
	padding: 0 10px;
	position: relative;

	background: $color3;

	font-family: $altfont;
	font-weight: 900;
	@include font-size(20);
	color: $textlight;

	&::after {
		content: "";
		display: block;
		height: 32px;
		width: 40px;
		position: absolute;
		top: 0;
		right: -40px;

		background: transparent url('#{$imgPath}borders-32.png') no-repeat -120px -32px;
	}
}

.travel-details {
	@include clearfix;

	.travel-details-meta {
		width: 270px;
		margin-right: $gutter;
		float: left;
		position: relative;

		.travel-label {
			position: relative;
		}

		ul.travel-list-meta li {
			line-height: 20px;
		}

		a.reserve {
			display: block;
			width: 171px;
			height: 46px;
			background: transparent url(../../img/bouton-reserve.png) 0 0 no-repeat;
			line-height: 38px;
			padding: 0 10px;
			text-align: center;
			text-decoration: none;
			font-family: $altfont;
			font-weight: 700;
			@include font-size(18);
			color: $textlight;
			margin: 0 0 12px 40px;

			&:hover {
				color: $color1;
			}
		}

		.guide-list {
			@include clearfix;
		}

		.guide-item {
			float: left;
			width: 130px;
			height: 130px;
			position: relative;
			color: white;

			&:nth-of-type(odd) {
				margin-right: 10px;
			}

			span {
				display: block;
				width: 130px;
				padding: 2px;
				position: absolute;
				bottom: 0;
				background-color: rgba(#000, 0.2);
				text-align: center;
			}
		}

		.img-info {
			width: 140px;
			height: 60px;
			position: absolute;
			right: 0;
			top: 105px;
		}

		ul.travel-link li a {
			display: block;
			width: 240px;
			height: 26px;
			position: relative;
			margin-bottom: 4px;
			color: $textlight;
			text-decoration: none;
			text-transform: uppercase;
			line-height: 26px;
			font-size: 0.9em;
			padding-left: 6px;

			&:hover {
				color: $color3;
			}

			&.question {
				background: $color2;

				&:after {
					content: "";
					display: block;
					width: 19px;
					height: 26px;
					position: absolute;
					top: 0;
					right: -19px;
					background: transparent url('#{$imgPath}borders-26.png') 0 0 no-repeat;
				}
			}

			&.telecharge {
				background: $color1;

				&::after {
					content: "";
					display: block;
					width: 19px;
					height: 26px;
					position: absolute;
					top: 0;
					right: -19px;
					background: transparent url('#{$imgPath}borders-26.png') 0 -26px no-repeat;
				}
			}
		}
	}
}

.travel-details-precision {
	margin-top: 34px;

	ul.details-menu {
		display: block;
		height: 34px;
		width: 100%;

		li {
			float: left;

			a {
				display: block;
				height: 34px;
				background: #babbbb;
				color: $textlight;
				text-transform: uppercase;
				text-decoration: none;
				line-height: 34px;
				text-align: center;

				&:hover, &.selected {
					background: $color3;
				}
			}

			&:first-of-type a {
				// padding-left: 22px;
			}

			&:last-of-type a {
				// padding-right: 21px;
			}
		}
	}

	.detail {
		@include clearfix();

		.colonne {
			width: 310px;
			float: left;
			margin-right: 35px;

			&:last-of-type {
				margin: 0;
			}
		}

		&.hide {
			display: none;
		}

		ul {


			li {
				list-style-type: none;
				margin-top: 8px;
				line-height: 17px;
				padding-left: 22px;
				// list-style-image: url(../img/puce.png);
				background: transparent url(../../img/puce.png) 0 1px no-repeat;
			}
		}

		a.galerie {
			display: block;
			float: left;
			width: 247px;
			height: 150px;
			margin: 0 3px 3px 0;
		}
	}
}

.programme h2 {
	list-style-type: none;
	margin: 8px 0;
	line-height: 17px;
	padding-left: 22px;
	font-weight: bold;
	background: transparent url(../../img/puce.png) 0 1px no-repeat;
}

.programme #map {
	float: right;
	margin: 0 0 10px 10px;
}

.voyage, .programme, .avis, .photo {

	ul, .text {
		width: 460px;
		text-align: justify;
		margin-right: 40px;
		float: left;
	}


	.ptsforts {
		@include clearfix;
		display: block;
		float: left;

		.fb-like {
			margin-top: 20px;

			&.fb_iframe_widget {
				display: block;
			}
		}
	}

	.vignettes {
		@include clearfix();
		margin-bottom: 15px;

		h3 {
			text-transform: uppercase;
			@include font-size(12);
			// font-weight: 600;
			color: $color3;
			margin-bottom: 5px;
		}

		a:hover {
			opacity: 0.9;
			transition: all 0.2s ease-in-out;
		}
	}

	.info-right {
		width: 500px;
		height: auto;
		float: right;
	}

	.video {
		iframe {
			width: 500px;
		}

		p {
			display: none;
		}
	}

	.video-subst {
		display: block;
		height: auto;

		img {
			width: 500px;
			height: 100%;
		}
	}

	.guide--encart {
		@include clearfix;
		width: 100%;
		height: auto;
		background-color: lighten($color4, 5%);
		margin-top: 10px;

		.guide--photo {
			float: left;
			padding: 10px;
			background-color: lighten($color4, 5%);
		}

		.guide--info {
			float: left;
			width: 320px;
			padding: 10px;

			.title-lev3 {
				margin-bottom: 1.4em;
			}
		}
	}

	.videominiature {
		iframe {
			width: 247px;
			height: 150px;
		}

		p {
			display: none;
		}
	}

	.ajout-exp {
		margin: 20px 0 0 0;

		form ul li {
			background: none;
			padding: 0;
			margin-top: 5px;
		}
	}
}

#map {
	width: 500px;
	height: 300px;
	float: right;
	background: #fff;
}

.avis .text {

	strong {
		display: block;
		margin-bottom: 8px;
		// color: $color2;
	}

	.auteur {
		color: $color1;
		font-weight: 500;
		// 	font-style: italic;
	}
}

a.savoir {
	color: $color2;
	text-decoration: none;
	font-size: 0.9em;
	margin-left: 25px;

	&:hover {
		color: $color3;
	}
}

.niveaux {
	margin: 15px auto 8px auto;
}

.niveaux-list li {
	line-height: 25px;

	.titre-level {
		display: block;
		width: 70px;
		float: left;
	}

	.pictos-levels {
		display: inline-block;
		width: 125px;
		height: 27px;
		margin-left: 10px;
		background: transparent url('#{$imgPath}pictos-levels.png') no-repeat 0 0;
	}

	.immersion {
		background-position: 0 0;

		&.level-2 {
			background-position: 0 -27px;
		}

		&.level-3 {
			background-position: 0 -54px;
		}

		&.level-4 {
			background-position: 0 -81px;
		}

		&.level-5 {
			background-position: 0 -108px;
		}
	}

	.rusticite {
		background-position: -125px 0;

		&.level-2 {
			background-position: -125px -27px;
		}

		&.level-3 {
			background-position: -125px -54px;
		}

		&.level-4 {
			background-position: -125px -81px;
		}

		&.level-5 {
			background-position: -125px -108px;
		}
	}

	.autonomie {
		background-position: -250px 0;

		&.level-2 {
			background-position: -250px -27px;
		}

		&.level-3 {
			background-position: -250px -54px;
		}

		&.level-4 {
			background-position: -250px -81px;
		}

		&.level-5 {
			background-position: -250px -108px;
		}
	}

	.physique {
		background-position: -375px 0;

		&.level-2 {
			background-position: -375px -27px;
		}

		&.level-3 {
			background-position: -375px -54px;
		}

		&.level-4 {
			background-position: -375px -81px;
		}

		&.level-5 {
			background-position: -375px -108px;
		}
	}
}

a.link-news {
	display: block;
	width: 150px;
	height: 20px;
	line-height: 20px;
	text-align: center;
	position: absolute;
	bottom: 20px;
	right: 45px;

	&.link-actu {
		background: darken($color1, 10%);
		color: darken($color1, 35%);

		&:hover {
			background: darken($color1, 30%);
			color: $textlight;
		}
	}

	&.link-exp {
		background: darken($color2, 10%);
		color: darken($color2, 35%);
		bottom: 50px;

		&:hover {
			background: darken($color2, 30%);
			color: $textlight;
		}
	}
}

/* -- Formulaire Fiche technique -- */
#fiche {

	h3 {
		text-transform: uppercase;
		color: $color3;
		margin-bottom: 10px;
	}

	.email {
		width: 470px;
	}

	ul li {
		display: block;
		height: 30px;
		float: left;
		// margin-bottom: 8px;

		label {
			display: block;
			float: left;
			line-height: 22px;
			background: $color1;
			color: $textlight;
			width: 80px;
			padding-left: 8px;
		}

		input {
			float: left;
			height: 22px;
			outline: none;
			padding-left: 8px;
		}
	}

	button {
		width: 550px;
		height: 25px;
		padding: 4px;
		background: $textlight;
		color: darken($color1, 10%);
		margin-top: 5px;
		border: 1px solid $color1;
		border-radius: 4px;
		text-transform: uppercase;

		&:hover {
			color: $textlight;
			background: $color1;
			transition: all 0.2s ease-in-out;
		}
	}

	p.retour {
		display: block;
		color: #000;
	}
}


/* Actualités */
/* Liste */
.listeactu-text {
	text-align: justify;
}


.listeactu-date {
	display: inline-block;
	height: 25px;
	line-height: 25px;
	margin-top: 48px;
	padding: 0 10px;
	position: relative;

	background: $color3;

	font-family: $altfont;
	@include font-size(15);
	color: $textlight;

	&::after {
		content: "";
		display: block;
		height: 25px;
		width: 40px;
		position: absolute;
		top: 0;
		right: -30px;

		background: transparent url('#{$imgPath}borders-32.png') no-repeat -120px -32px;
	}
}

.actu-pagination {
	@include clearfix();
	float: right;
	margin: 40px 20px 20px 0;
	clear: both;

	span {
		display: block;
		float: left;
		width: 50px;
		height: 30px;
		line-height: 30px;
		color: $color2;
	}

	ul {
		float: left;

		li {
			list-style-type: none;
			float: left;
			margin: 0 1px;
		}

		a {
			display: block;
			width: 18px;
			height: 18px;
			line-height: 18px;
			text-align: center;
			// background: $color2;
			border: 1px solid $color2;
			color: $color2;
			text-decoration: none;

			transition: all 0.2s ease-in-out;

			&:hover, &:focus, &:active {
				background: $color2;
				color: #fff;
			}
		}

		.selected a {
			border-color: $color2;
			background: $color2;
			color: #fff;
			cursor: default;
		}
	}
}

.img-list-actu {
	display: block;
	width: 225px;
	height: 150px;
	background-color: #ddd;
	text-align: center;
}

/* Détail actu */
.detail-actu {

	.content {
		width: 460px;
		float: left;
		margin: 0 20px;

		span.date {
			display: block;
			margin-top: 20px;
			color: $color3;
			font-weight: 800;
		}
	}
}

.image-actu {
	float: right;
	margin-right: 20px;
	width: 480px;
	height: 250px;
	background-color: #ddd;
	// border: 1px solid #ddd;
	text-align: center;

	img {
		max-width: 478px;
		max-height: 248px;
	}
}


ul.menu-amarok {
	float: right;
	margin: 0 20px 0 0;

	li {

		a {
			display: block;
			text-transform: uppercase;
			text-decoration: none;
			text-align: center;
			color: $textlight;
			background: $color1;
			width: 200px;
			height: 30px;
			line-height: 30px;
			margin-bottom: 1px;

			transition: all 0.25s ease-in-out;

			&:hover, &.selected {
				background: darken($color1, 10%);
				transition: all 0.25s ease-in-out;
			}
		}
	}
}

/* Page */

.amarok {
	width: 780px;

	.prez {
		margin-bottom: 40px;

		img {
			margin-bottom: 10px;
		}

		h3 {
			line-height: 20px;
			margin: 25px auto 15px auto;
		}

		p {
			line-height: 15px;
			// text-align: justify;

			span {
				font-weight: 500;
				color: $color1;
			}
		}

		ul.colonne {
			position: relative;
			margin-top: 20px;

			li.detail {
				list-style-type: none;
				display: block;
				background: none;
				padding-bottom: 20px;
				margin: 0 0 10px 0;
				border-top: 1px solid $color2;
				width: 370px;
				float: left;

				p {
					width: 340px;
				}

				span {
					font-weight: 800;

					&.italic {
						font-weight: normal;
						font-style: italic;
					}
				}

				ul {
					li.list-level1 {
						background: none;
						padding: 0;
						margin-left: 20px;
						list-style-type: circle;
						width: 310px;
					}
				}

				ul {
					li.list-level2 {
						margin: 8px 0 0 0;
						line-height: 17px;
						padding-left: 22px;
						list-style-type: none;
						background: transparent url(../../img/puce.png) 0 1px no-repeat;
						width: 340px;
					}
				}
			}
		}
	}
}


/* Contact */
.contact {

	.col-left {
		float: left;

		.coordonnees {
			margin-bottom: 20px;

			h4 {
				color: $color1;
				margin: 0;
			}

			.text {

				a {
					color: $color1;
					text-decoration: none;

					&:hover {
						color: darken($color1, 10%);
					}
				}
			}
		}

		form {
			@include clearfix;
			width: 960px;
			background: #eee;
			padding: 20px;

			ul {
				// width: 920px;
				width: 450px;
				float: left;
				margin-right: 10px;

				&:last-of-type {
					margin-right: 0;
					margin-left: 10px;
				}

				li {
					margin: 5px 0px;
					float: left;
					border: 1px solid transparent;

					&:last-of-type {
						margin-bottom: 0;
					}

					label {
						width: 130px;
						height: 35px;
						display: block;
						color: $textlight;
						float: left;
						line-height: 35px;
						background: $color1;
						padding-left: 15px;

						// sup {
						// 	color: $color2;
						// }
					}

					input {
						width: 318px;
						height: 35px;
						float: left;
						border: none;
						outline: none;
						padding: 10px;

						&:hover, &:focus {
							border: 1px solid $color1;
							transition: all 0.1s ease-in-out;
						}
					}

					span.mandatory {

						@include font-size(10);
						color: #aaa;
						margin: 0;

						sup {
							color: $color2;
						}
					}

					&.message {
						width: 450px;
						height: 35px;
						display: block;
						color: $textlight;
						float: left;
						line-height: 35px;
						background: $color1;
						padding-left: 15px;
						margin: 6px 0 0 1px;
					}

					textarea {
						width: 450px;
						height: 128px;
						border: none;
						outline: none;
						padding: 10px;
						margin: 7px 0 1px 0;
						border: 1px solid transparent;

						&:hover, &:focus {
							border: 1px solid $color1;
							transition: all 0.1s ease-in-out;
						}
					}
				}
			}

			button {
				width: 450px;
				height: 35px;
				@include font-size(12);
				background: #efefef;
				color: $color2;
				margin: -5px 0 0 10px;
				border: 1px solid $color2;
				// border-radius: 0;

				&:hover {
					color: $textlight;
					background: $color2;
				}
			}
		}
	}
}

.illu {
	width: 580px;
	height: 386px;
	background: transparent url(../../img/contact2.jpg) 0 0 no-repeat;
	float: right;
	margin-right: 20px;
}


/* 
########    #######   ##     ##  ####  ########   ########   
##         ##     ##  ##     ##   ##   ##     ##  ##         
##         ##     ##  ##     ##   ##   ##     ##  ##         
######     ##     ##  ##     ##   ##   ########   ######     
##         ##  ## ##  ##     ##   ##   ##         ##         
##         ##    ##   ##     ##   ##   ##         ##         
########    ##### ##   #######   ####  ##         ########   
*/
.equipe--title {
	margin-bottom: 0.5em !important;
}

.equipe--groupe {
	margin-bottom: 4em;

	&:last-of-type {
		margin-bottom: 2em;
	}

	.title-lev2 {
		margin-top: 12px;
		text-transform: none;
	}

	.equipe-cell {
		width: 300px;
	}
}

.equipe--title-lev3 {
	margin: 1.2em 0;
}

.equipe--intro {
	margin-bottom: 0.5em;
}

.equipe--photo {
	height: auto;
	width: auto;

	img {
		height: 100%;
		width: 100%;
	}
}

.equipe--fonction {
	display: block;
	margin: 0 0 2em 0;
	font-size: 1.3rem;
	position: relative;
	max-width: 240px;

	.text {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		display: inline-block;
		max-width: 100%;
	}
}


/* Newsletter */
.newsletter {

	form {
		@include clearfix;
		width: 960px;
		background: #eee;
		padding: 20px;

		ul {
			@include clearfix();
			width: 660px;
			margin: 0 auto;

			li {
				width: 660px;
				margin: 5px 0px;
				float: left;

				&:last-of-type {
					margin-bottom: 0;
				}

				label {
					width: 130px;
					height: 35px;
					display: block;
					color: $textlight;
					float: left;
					line-height: 35px;
					background: $color1;
					padding-left: 15px;

					// sup {
					// 	color: $color2;
					// }
				}

				input {
					width: 530px;
					height: 35px;
					float: left;
					border: none;
					outline: none;
					padding: 10px;
					border: 1px solid transparent;

					&:hover, &:focus {
						border: 1px solid $color1;
						transition: all 0.2s ease-in-out;
					}
				}

				span.mandatory {

					@include font-size(10);
					color: #aaa;
					margin: 0;

					sup {
						color: $color2;
					}
				}
			}
		}

		button {
			display: block;
			width: 660px;
			height: 35px;
			@include font-size(12);
			background: #efefef;
			color: $color2;
			margin: 10px auto 5px auto;
			border: 1px solid $color2;
			// border-radius: 0;

			&:hover {
				color: $textlight;
				background: $color2;
			}
		}
	}
}


/* Footer */
.footer-main {
	width: 1000px;
	margin: auto;
	@include clearfix();
	margin-top: $gutter;
	padding: 15px 20px 10px 20px;
	position: relative;

	background: $color4;

	font-size: 0.8em;
}

.footer-container {
	&:before,
	&:after {
		content: "";
		display: block;
		width: 89px;
		height: 110px;
		position: absolute;
		top: -20px;
		left: -89px;

		background: transparent url('#{$imgPath}bg-footer.png') 0 0 no-repeat;
	}

	&:after {
		top: auto;
		left: auto;
		bottom: 20px;
		right: -89px;

		background-position: -10px -120px;
	}
}

.footer-block {
	width: 180px;
	margin-right: 20px;
	float: left;

	font-family: $altfont;

	p + ul {
		margin-top: 10px;
	}

	li {
		padding: 1px 0;
		text-transform: uppercase;
	}

	a {
		color: $text;
		text-decoration: none;

		transition: all 0.2s ease-in-out;

		&:hover, &:focus, &:active {
			color: $textlight;
			background: $text;
		}
	}

	h6 {
		@include font-size(11);
	}

	&.footer-destination {
		width: 245px;

		.destination-container{
			display: flex;
			ul{
				margin-right: 10px;
			}
		}

	}
}

.logo-tourisme-responsable {
	display: block;
	width: 103px;
	height: 61px;
	position: absolute;
	top: $gutter;
	right: $gutter;

	background: transparent url('#{$imgPath}logo-tourisme-responsable.png') no-repeat 0 0;
	text-indent: -9999px;
}

.logo-terra-scientifica {
	width: 130px;
	height: auto;
	position: absolute;
	right: 170px;
	top: 110px;
}

.logo-jama {
	width: 130px;
	height: auto;
	position: absolute;
	right: $gutter;
	top: 100px;
}

.logo-credit-cooperatif, .logo-caisse-epargne {
	width: 120px;
	height: auto;
	position: absolute;
	right: $gutter;
	top: 217px;
}

.logo-cheque-vacances {
	width: 120px;
	height: auto;
	position: absolute;
	right: 150px;
	top: 210px;
}

.credit-photo {
	position: absolute;
	bottom: 5px;
	right: $gutter;
	color: #999;
}

.credit {
	display: block;
	text-align: center;
	@include font-size(10);
	margin: 5px 0 15px 0;
	color: #888;
	line-height: 15px;
	margin: 10px auto;

	a {
		color: #888;
	}
}

/* 
########   ##          #######    ######          ######    ########    #######   
##     ##  ##         ##     ##  ##    ##        ##    ##   ##         ##     ##  
##     ##  ##         ##     ##  ##              ##         ##         ##     ##  
########   ##         ##     ##  ##               ######    ######     ##     ##  
##     ##  ##         ##     ##  ##                    ##   ##         ##     ##  
##     ##  ##         ##     ##  ##    ##        ##    ##   ##         ##     ##  
########   ########    #######    ######          ######    ########    #######   
 */


.seo-bloc {
	background-color: #f5f5f5;

	.seo-title {
		@include font-size(14);
		font-weight: bold;
		margin: 10px 0;
	}

	.seo-content {
		@include font-size(11);

		p {
			margin: 10px 0;
			text-align: justify;
		}

		ul {
			list-style: disc;
			margin-left: 20px;
		}


		a {
			color: #000;
			text-decoration: none;
		}

		a:hover {
			color: #F49200;
		}
	}
}

[data-link] {
	cursor: pointer;
}

.membre-photo {
	img {
		max-width: 100%;
		height: auto;
	}
}

/// Transition photos page détail ///
.slider-container {
	width: 690px;
	float: left;
	position: relative;
	z-index: 1;
	overflow: hidden;
	margin-right: -20px;


	&.full-width {
		width: 100%;
		height: auto;
	}

	.slick-track {
		height: 460px;
	}

	.slick-prev,
	.slick-next {
		position: absolute;
		top: 20px;
		z-index: +1;
		font-size: 0;
		height: 21px;
		width: 21px;
		border: none;
		outline: 0;

		&:hover {
			background-color: #000;
		}
	}

	.slick-prev {
		right: 42px;
		background: grey url(#{$imgPath}slider/arrow-prev.png) 0 0 no-repeat;

	}

	.slick-next {
		right: 20px;
		background: grey url(#{$imgPath}slider/arrow-next.png) 0 0 no-repeat;
	}

	.slick-slide {
		&:focus {
			outline: none;
		}
	}
}

.membre-galerie {
	display: flex;
	position: relative;
	flex-wrap: wrap;

	.item {
		flex-basis: 25%;
		padding: 0 3px 3px 0;

		img {
			width: 100%;
		}
	}
}