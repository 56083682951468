@mixin retina() {
	@media only screen and (-webkit-min-device-pixel-ratio : 1.5),
	only screen and (min-device-pixel-ratio : 1.5) {
		@content;
	}
}

@mixin ret($img, $bgsize) {
	@media only screen and (-webkit-min-device-pixel-ratio : 1.5),
	only screen and (min-device-pixel-ratio : 1.5) {
		background-image: $img;
		-webkit-background-size: $bgsize;
		background-size: $bgsize;
	}
}

@mixin font-size($font-size: 16) {
	font-size : #{$font-size}px;
	font-size : #{$font-size / 10}rem;
}

@mixin clearfix() {
	&::after {
		content: "";
		display: table;
		clear: both;
	}
}

@mixin center($width: null, $height: null) {
	position: absolute;
	top: 50%;
	left: 50%;

	@if not $width and not $height {
		transform: translate(-50%, -50%);
	} @else if $width and $height {
		width: $width;
		height: $height;
		margin: -($width / 2) #{0 0} -($height / 2);
	} @else if not $height {
		width: $width;
		margin-left: -($width / 2);
		transform: translateY(-50%);
	} @else {
		height: $height;
		margin-top: -($height / 2);
		transform: translateX(-50%);
	}
}